import React from 'react'
import "../Loader/Loader.css";

function Loader() {
  return (
    <div  style={{zIndex:999,background: '#00000040'}} className='w-100 d-flex justify-content-center align-items-center vh-100 position-absolute  '>
        <div class="spinner"></div>
    </div>
  )
}

export default Loader