import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DataTable from "../../Components/DataTable/DataTable";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import { ManageApis } from "../../AppServices/ManageApi";
import {
  lengthValidation1,
  preventSpecialCharsandAllowHyphen,
  preventSpecialCharsandNum,
  sanitizePhoneNumber2,
  validateLength,
} from "../../Common/Utils/Utils";
import { globalContext } from "../../App";
import moment from "moment";
function Collections() {
  const [selectedTab, setSelectedTab] = useState("collections");
  const [collectionsTableVisible, setCollectionsTableVisible] = useState(true);
  const [VisibleschemeDetails, setVisibleschemeDetails] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [TableData, setTableData] = useState([]);
  const [pageSize, setpageSize] = useState(10);
  const [TotalCount, setTotalCount] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [isExpand, setisExpand] = useState('');
  const { setIsLoader } = useContext(globalContext);
  const [filterVal,setFilterVal]=useState({
    agentId:"",
    agentName:"",
    Date:"",
    casba:"",
    chittyId:"",
    ChittalNo:"",
    transactionId:""
  })
  const [isFilterReset, setIsFilterReset] = useState(false);
  const [filter,setFilter]=useState(false)
  const previousTab = useRef(selectedTab);
  const collectionData = [
    {
      title: "Sl No",
      dataIndex: "key",
      sorter: (a, b) => {
        if (a.key > b.key) {
          return 1;
        }
        if (a.key < b.key) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
    },
    {
      title: "DC Agent ID",
      dataIndex: "agentId",
      sorter: (a, b) => {
        if (a.agentId > b.agentId) {
          return 1;
        }
        if (a.agentId < b.agentId) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
    },
    {
      title: "DC Agent Name",
      dataIndex: "agentName",
      sorter: (a, b) => {
        if (a.agentName > b.agentName) {
          return 1;
        }
        if (a.agentName < b.agentName) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      sorter: (a, b) => {
        if (a.customerName > b.customerName) {
          return 1;
        }
        if (a.customerName < b.customerName) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
    },

    {
      title: "Amount Collected",
      dataIndex: "amountPaid",
      sorter: (a, b) => a.amountPaid - b.amountPaid,
      render: (text) => text || "NA",
    },
    {
      title: "Collected Date",
      dataIndex: "created_date",
      dataIndex: "created_date",
      render: (text) => text || "NA",
    },
    {
      title: "Status",
      dataIndex: "collection_status",
      render: (text, record, index) => {
        let statusText;
        let backgroundColor;
        let color="black";
        const collectionStatus = record.collection_status;
        const postingStatus = record?.posting_status !== undefined ? record?.posting_status : 0;

    if (collectionStatus === 0) {
      statusText = postingStatus === 0 ? "Pending" : "Processing";
      backgroundColor = postingStatus === 0 ? "rgba(214, 162, 67, 0.30)" : "rgba(255, 165, 0, 0.30)";
    } else if (collectionStatus === 1 && postingStatus === 2) {
      statusText = "Success";
      backgroundColor = "rgba(193, 221, 117, 0.30)";
    } else {
      statusText = "Processing";
      backgroundColor = "rgba(255, 165, 0, 0.30)";
    }

        return (
          <div>
            <span
              style={{
                backgroundColor: backgroundColor,
                display: "block",
                textAlign: "center",
                fontWeight: "500",
                color: color,
                borderRadius: "20px",
                minWidth: "70px",
                maxWidth: "80px",
              }}
            >
              {statusText}
            </span>
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "",
      render: (text, record, index) => {
        return (
          <div>
            {isExpand == record._id ? (
              <i className="fas fa-chevron-up text_master"></i>
            ) : (
              <i className="fas fa-chevron-down text_master"></i>
            )}
          </div>
        );
      },
    },
  ];

  //Scheme Details
  const SchemeData = [
    {
      title: "Sl No.",
      dataIndex: "key",
      sorter: (a, b) => {
        if (a.key > b.key) {
          return 1;
        }
        if (a.key < b.key) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      sorter: (a, b) => {
        if (a.customerName > b.customerName) {
          return 1;
        }
        if (a.customerName < b.customerName) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
    },
    {
      title: "Chittal No",
      dataIndex: "chittalNo",
      sorter: (a, b) => a.chittalNo - b.chittalNo,
      render: (text) => text || "NA",
    },

    {
      title: "Amount Collected",
      dataIndex: "amountPaid",
      sorter: (a, b) => a.amountPaid - b.amountPaid,
      render: (text) => text || "NA",
    },
    {
      title: "Cash Amount",
      dataIndex: "paidByCash",
      sorter: (a, b) => a.paidByCash - b.paidByCash,
      render: (text) => text || "NA",
    },
    {
      title: "Cheque Amount",
      dataIndex: "paidByCheque",
      sorter: (a, b) => a.paidByCheque - b.paidByCheque,
      render: (text) => text || "NA",
    },
    {
      title: "UPI Amount",
      dataIndex: "paidByUPI",
      sorter: (a, b) => a.paidByUPI - b.paidByUPI,
      render: (text) => text || "NA",
    },
    {
      title: "Collected Date",
      dataIndex: "created_date",
      render: (text) => text || "NA",
    },

    {
      title: "Casba Sync Status",
      dataIndex: "collection_status",
      render: (text, record, index) => {
        let statusText;
        let backgroundColor;
        let color="black";
        const collectionStatus = record.collection_status;
        const postingStatus = record?.posting_status !== undefined ? record?.posting_status : 0;

    if (collectionStatus === 0) {
      statusText = postingStatus === 0 ? "Pending" : "Processing";
      backgroundColor = postingStatus === 0 ? "rgba(214, 162, 67, 0.30)" : "rgba(255, 165, 0, 0.30)";
    } else if (collectionStatus === 1 && postingStatus === 2) {
      statusText = "Success";
      backgroundColor = "rgba(193, 221, 117, 0.30)";
    } else {
      statusText = "Processing";
      backgroundColor = "rgba(255, 165, 0, 0.30)";
    }

        return (
          <div>
            <span
              style={{
                backgroundColor: backgroundColor,
                display: "block",
                textAlign: "center",
                fontWeight: "600",
                color: color,
                borderRadius: "20px",
                minWidth: "100px",
                maxWidth: "100px",
              }}
            >
              {statusText}
            </span>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      render: (text, record, index) => {
        // console.log(text, record, index);
        return (
          <div>
            {isExpand == record._id ? (
              <i className="fas fa-chevron-up text_master"></i>
            ) : (
              <i className="fas fa-chevron-down text_master"></i>
            )}
          </div>
        );
      },
    },
  ];

  //Expansion Data
  const ExpandedCollectionData = [
    {
      title: "Chittal No.",
      dataIndex: "chittalNo",
      render: (text) => text || "NA",
    },
    {
      title: "Chitty Number",
      dataIndex: "chittyId",
      render: (text) => text || "NA",
    },
    {
      title: "Branch",
      dataIndex: "branchName",
      render: (text) => text || "NA",
    },
    {
      title: "Paid by cash",
      dataIndex: "paidByCash",
      render: (text) => text || "NA",
    },
    {
      title: "Paid by cheque",
      dataIndex: "paidByCheque",
      render: (text) => text || "NA",
    },
    {
      title: "Paid by upi",
      dataIndex: "paidByUPI",
      render: (text) => text || "NA",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      render: (text) => text || "NA",
    },
  ];
  const ExpandedSchemeData = [
    {
      title: "Chitty Number",
      dataIndex: "chittyId",
      render: (text) => text || "NA",
    },
    {
      title: "Branch",
      dataIndex: "branchName",
      render: (text) => text || "NA",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      render: (text) => text || "NA",
    },
  ];

  const casbaStatus = [
    {
      value: "0",
      label: "Pending",
    },
    {
      value: "1",
      label: "Success",
    },
    {
      value: "2",
      label: "Processing",
    },
  ];

  //Filtering Section
  let filterCollectionList = [
    {
      type: "text",
      placeholder: "Agent ID",
      name: "agentId",
    },

    {
      type: "text",
      placeholder: "Agent Name",
      name: "agentName",
    },
    {
      type: "text",
      placeholder: "Transaction ID",
      name: "transactionId",
    },
    {
      type: "date-range",
      placeholder: "Date",
      name: "Date",
      value:filterVal.Date
    },

  ];
  const [filterChange, setFilterChange] = useState(filterCollectionList);


  let filterSchemeData = [
    {
      type: "text",
      placeholder: "Chitty Number",
      name: "chittyId",
    },
    {
      type: "text",
      placeholder: "Chittal No",
      name: "ChittalNo",
    },
    {
      type: "date-range",
      placeholder: "Date",
      name: "Date",
      value: filterVal.Date,
    },
    {
      type: "select",
      placeholder: "Casba Sync Status",
      name: "casba",
      options: [...casbaStatus],
    },
  ];

  

  const handleTabChange = (tab) => {
    if (tab === selectedTab) {
      return;
    }
    setTableData([]);
    setSelectedTab(tab);
    setisExpand(prev=>"")
    if (tab === "collections") {
      setCollectionsTableVisible(true);
      setVisibleschemeDetails(false);
      setFilterChange(filterCollectionList);
    } else if (tab === "schemeDetails") {
      setCollectionsTableVisible(false);
      setVisibleschemeDetails(true);
      setFilterChange(filterSchemeData);
    }
handleFilterReset()
  };



  const handleOnChange = (value, label, index, name) => {
    let NameValue;
    setErrorMsg((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "agentName") {
      NameValue = preventSpecialCharsandNum(validateLength(value,30));
      setErrorMsg((pre) => ({
        ...pre,
        [name]: lengthValidation1(NameValue, 3),
      }));
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "agentId") {
      NameValue =sanitizePhoneNumber2(validateLength(value,15));
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "casba") {
      NameValue = value;
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    }else if(name === "chittyId"){
      NameValue =validateLength(value,16);
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    }else if(name==="Date"){
      NameValue=value;
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "ChittalNo") {
      NameValue =sanitizePhoneNumber2(validateLength(value, 5));
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "transactionId") {
      const regex = /^TX-[a-zA-Z0-9]{6}$/;
      NameValue = preventSpecialCharsandAllowHyphen(validateLength(value, 9));
      if(!regex.test(NameValue)){
        setErrorMsg((prev) => ({
          ...prev,
          [name]: "Enter a valid transaction ID (e.g., TX-123abc)",
        }));
      }else{
        setErrorMsg((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    }else {
      NameValue = value;
    }
    setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
  };

  const handleFilterReset = () => {
    setFilterVal({
      agentId:"",
      agentName:"",
      casba:"",
      chittyId:"",
      ChittalNo:"",
      Date:"",
    })
    setcurrentPage(1)
    setErrorMsg({});
    setIsFilterReset(true);
  };


  function expandedRowRender(record) {
    const filteredData = TableData.filter((item) => item.key === record.key);
    if (selectedTab == "schemeDetails") {
      return (
        <>
          <div
            style={{ width: "46%", paddingLeft: "0px" }}
            className="expand_inner_table"
          >
            <DataTable columns={ExpandedSchemeData} rowData={filteredData} />
          </div>
        </>
      );
    } else if (selectedTab == "collections") {
      return (
        <>
          <div
            style={{ width: "95%", paddingLeft: "0px" }}
            className="expand_inner_table"
          >
            <DataTable
              columns={ExpandedCollectionData}
              rowData={filteredData}
            />
          </div>
        </>
      );
      // }
    }
  }
  useEffect(() => {
    if (isFilterReset) {
      setFilter(false);
      setIsFilterReset(false);
    } else {
      fetchTableDatas();
    }
  }, [isFilterReset, currentPage, pageSize]);


  useEffect(()=>{
    if (previousTab.current !== selectedTab) {
      fetchTableDatas(1)
      previousTab.current = selectedTab;
    }
  },[selectedTab])
  const fetchTableDatas = async (page) => {
    try {
      setIsLoader(true)
      let path;
      let reqstBody = {};
      let apiCalls = [];
      const agentIdValue = filterVal.agentId;
      const agentNameValue = filterVal.agentName;
      const chittalNoValue = filterVal.ChittalNo;
      const chittyIdValue = filterVal.chittyId;
      const startDateValue = filterVal?.Date?.[0];
      const endDateValue = filterVal?.Date?.[1];
      const casbaStatusValue = filterVal.casba;
      const transactionId = filterVal.transactionId;
      const startingIndex = (currentPage - 1) * pageSize + 1;
 if(page){
  setcurrentPage(prev =>page)
 }
      switch (selectedTab) {
        case "collections":
          let params = agentNameValue ? `agentName=${agentNameValue}&` : "";
          params = params + (agentIdValue ? `agentId=${agentIdValue}&` : "");
          params = params + (transactionId ? `transactionId=${transactionId}&` : "");
          params =
            params + (startDateValue && endDateValue ? `startDate=${startDateValue}&endDate=${endDateValue}&` : "");
          path =
            global.getCollectionDetails +
            `?${params}page=${currentPage}&limit=${pageSize}`;
          reqstBody = {};
          break;
        case "schemeDetails":
          let params1 = chittalNoValue ? `chittalNo=${chittalNoValue}&` : "";
          params1 =
            params1 + (chittyIdValue ? `chit_id=${chittyIdValue}&` : "");
            params1 =params1 + (startDateValue && endDateValue ? `startDate=${startDateValue}&endDate=${endDateValue}&` : "");
            params1 = params1 + (casbaStatusValue ? `status=${casbaStatusValue}&` : "");
          path = global.getSchemeDetails +`?${params1}page=${currentPage}&limit=${pageSize}`;
          reqstBody = {};
          break;
        default:
          break;
      }

      apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));
      const responses = await Promise.all(apiCalls);

      responses.forEach(({ message, response_data }) => {
        if (message === "Success") {
          const { data } = response_data || {};
          setTableData(
            data.docs.map((itm, index) => ({
              ...itm,
              key: startingIndex + index,
              created_date: itm.created_date
              ?moment(itm.created_date).format("DD-MM-YYYY")
              : "",
              branchName:itm.branchDetails.branch_name,
            }))
          );
          setTotalCount(data.totalDocs);
          setpageSize(parseInt(data.limit));
        } else {
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  async function commonApiCall({ path, reqstBody, method = "post" }) {
    if(!path) return 
    try {
      const result = await ManageApis(method, path, reqstBody);
      // console.log("........====", result.response_data.data);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

 
  function onChangeTable(current, size) {
    setcurrentPage(current);
    setpageSize(parseInt(size));
  }

  return (
    <>
      <div className="d-flex justify-content-between ">
        <div className="d-flex">
          <div
            className={`bg_light7 position-relative headStyle1  w_fit_content min_w_160x px-4 pt-3 pb-2 ${
              selectedTab === "collections" ? "active-tab" : ""
            }`}
            onClick={() => handleTabChange("collections")}
            style={{ cursor: "pointer" }}
          >
            <h2 className="mb-0 collection_details mt-0 cursor-pointer text-start text_master font_16 font_500">
            Collection Details
            </h2>
            <div
              className={`headStyle ${
                selectedTab === "collections" ? "headStyle-active" : ""
              }`}
            ></div>
          </div>
          <div
            className={`ms-3 bg_light7 position-relative headStyle1 shadow_sm_4 w_fit_content min_w_160x px-4 pt-3 pb-2 ${
              selectedTab === "schemeDetails" ? "active-tab" : ""
            }`}
            onClick={() => handleTabChange("schemeDetails")}
            style={{ cursor: "pointer" }}
          >
            <h2 className="mb-0 mt-0 Transaction_details text-start text_master font_16 font_500">
              Scheme Details
            </h2>
            <div
              className={`headStyle ${
                selectedTab === "schemeDetails" ? "headStyle-active" : ""
              }`}
            ></div>
          </div>
        </div>
        <FilterComponent
          filterInputData={filterChange}
          handleChange={handleOnChange}
          handleReset={handleFilterReset}
          value={filterVal}
          errorMsg={errorMsg}
          handleApply={()=>{fetchTableDatas(1);setFilter(true)}}
          resetEnabled={filter}
          onclear={handleOnChange}
        />
      </div>

      {collectionsTableVisible && (
        <div className="pb-3  custom_pagination customise_table3 customise_table customise_Expand_table expandable-table shadow_sm_4 br_15  ">
          <DataTable
            rowData={TableData}
            columns={collectionData}
            expandedRowRender={expandedRowRender}
            currentPage={currentPage}
            pageSize={pageSize}
            onChangeTable={(current, Size) => onChangeTable(current, Size)}
            scroll={TableData.length > 5 ? { y: 400 } : ""}
            changeIcon={setisExpand}
            total={TotalCount}
            setPageSize={setpageSize}
          />
        </div>
      )}
      {VisibleschemeDetails && (
        <>
          <div className=" pb-3  custom_pagination customise_table3 customise_table customise_Expand_table expandable-table shadow_sm_4 br_15  ">
            <DataTable
              rowData={TableData}
              columns={SchemeData}
              pageSize={pageSize}
              currentPage={currentPage}
              expandedRowRender={expandedRowRender}
              changeIcon={setisExpand}
              total={TotalCount}
              onChangeTable={(current, Size) => onChangeTable(current, Size)}
              scroll={TableData.length > 5 ? { y: 400 } : ""}
              setPageSize={setpageSize}
            />
          </div>
        </>
      )}
    </>
  );
}

export default Collections;
