import React, {useState } from "react";
import AgentSummary from "../../Components/AgentSummary/AgentSummary";
import DailyTransactionSummary from "../../Components/DailyTransactionSummary/DailyTransactionSummary";
import MonthlyTransSummary from "../../Components/MonthlyTransSummary/MonthlyTransSummary";
import ModalComponent from "../../Components/ModalComponent/ModalComponent";
function Dashboard() {

  // const { user, setUser } = useContext(globalContext);
  const [visible, setVisible] = useState(true);
  
  const handleLogout=()=>{
    localStorage.clear();
    window.location.replace(global.vueBaseUrl);
  }
  const handleCancel=()=>{
    window.location.replace("/");
  }

  if (window.location.pathname.includes("logout")) {
return(
  <>
  <ModalComponent
          size="sm"
        visible={visible}
        handleClose={handleCancel}
        title=""
      >
        <h1 className="font_18 text-black font_400 mt-0">Are you sure you want to logout?</h1>
        <div className="text-center d-flex">
          <button className="btn font_15 font_500 text_master w-100" onClick={handleCancel}>Cancel</button>
          <button className="btn btn-master ms-2 font_15 font_500 w-100" onClick={handleLogout}>Ok</button>
        </div>
      </ModalComponent>
      </>
)
  } else if (window.location.pathname.includes("/agent-admin")) {
    return (
      <>
  

      <div className="p-3 dashboardLayout overflow-auto scrollbar1">
        <AgentSummary />
        <div className="row pt-4">
          <div className="col-12 col-lg-6">
            <DailyTransactionSummary />
          </div>
          <div className="col-12 col-lg-6 mt-3 mt-lg-0">
            <MonthlyTransSummary />
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Dashboard;
