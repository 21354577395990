import React, { useCallback, useContext, useEffect, useState } from "react";
import { ManageApis } from "../../AppServices/ManageApi";
import DataTable from "../../Components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../../App";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import {
  lengthValidation1,
  preventSpecialCharsandNum,
  sanitizePhoneNumber2,
  validateLength,
} from "../../Common/Utils/Utils";
function Reports() {
  const [TableData, setTableData] = useState([]);
  const [TotalCount, setTotalCount] = useState();
  const [pageSize, setpageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [branchstate, setBranchState] = useState([]);
  const [filterData, setFilterData] = useState({
    agentId: "",
    agentName: "",
    branch_name:"",
  });
  const Navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState({});
  const { setIsLoader,isBranchAdmin } = useContext(globalContext);
  const [isFilterReset, setIsFilterReset] = useState(false);
  const [filter,setFilter]=useState(false)
  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      sorter: (a, b) => {
        if (a.key > b.key) {
          return 1;
        }
        if (a.key < b.key) {
          return -1;
        }
        return 0;
      },
    },

    {
      title: "DC Agent ID",
      dataIndex: "agentId",
      sorter: (a, b) => {
        if (a.agentId > b.agentId) {
          return 1;
        }
        if (a.agentId < b.agentId) {
          return -1;
        }
        return 0;
      },
    },
    {
      title: "DC Agent Name",
      dataIndex: "name",
      sorter: (a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      },
    },

    {
      title: "Branch",
      dataIndex: "branchName",
      sorter: (a, b) => {
        if (a.branchName > b.branchName) {
          return 1;
        }
        if (a.branchName < b.branchName) {
          return -1;
        }
        return 0;
      },
    },

    {
      title: "Report",
      dataIndex: "action",
      render: (text, record, index) => (
        <span className="d-flex gap-2">
          {text} {/* Display the original text */}
          <span
            onClick={() => handleAction(record)}
            className="mt-1 w_fit_content reportsBtn br_5 py-1 font_14 px-2 text_master2_d1 rounded"
            style={{
              backgroundColor: index % 2 === 0 ? "white" : "#F3F5F7",
              /* Apply different background colors based on index */
            }}
          >
            <svg
              className="reportsBtnIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="26"
              viewBox="0 0 22 26"
              fill="none"
            >
              <path
                d="M1.998 16V24H16.5L20 20.5V2H1M15 24V19H20"
                stroke="#1D3B6D"
                stroke-width="2.0354"
              />
              <path
                d="M5 11.85C4.91716 11.85 4.85 11.9172 4.85 12C4.85 12.0828 4.91716 12.15 5 12.15L5 11.85ZM9.10607 12.1061C9.16464 12.0475 9.16464 11.9525 9.10607 11.8939L8.15147 10.9393C8.09289 10.8808 7.99792 10.8808 7.93934 10.9393C7.88076 10.9979 7.88076 11.0929 7.93934 11.1515L8.78787 12L7.93934 12.8485C7.88076 12.9071 7.88076 13.0021 7.93934 13.0607C7.99792 13.1192 8.09289 13.1192 8.15147 13.0607L9.10607 12.1061ZM5 12.15L9 12.15L9 11.85L5 11.85L5 12.15Z"
                fill="#1D3B6D"
              />
              <circle
                cx="10.8308"
                cy="11.0144"
                r="0.792207"
                stroke="#1D3B6D"
                stroke-width="0.444416"
              />
              <mask
                id="path-4-outside-1_963_1204"
                maskUnits="userSpaceOnUse"
                x="8.30469"
                y="11.7295"
                width="5"
                height="3"
                fill="black"
              >
                <rect
                  fill="white"
                  x="8.30469"
                  y="11.7295"
                  width="5"
                  height="3"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.5922 13.5782C12.6057 13.5972 12.5919 13.6231 12.5686 13.6231H9.33357C9.31029 13.6231 9.29652 13.5972 9.30999 13.5782C9.67425 13.0646 10.2735 12.7295 10.9511 12.7295C11.6286 12.7295 12.2279 13.0646 12.5922 13.5782Z"
                />
              </mask>
              <path
                d="M12.5922 13.5782L12.2297 13.8353L12.5922 13.5782ZM9.30999 13.5782L9.67248 13.8353L9.30999 13.5782ZM9.33357 14.0675H12.5686V13.1787H9.33357V14.0675ZM9.67248 13.8353C9.95699 13.4342 10.4237 13.1739 10.9511 13.1739V12.2851C10.1234 12.2851 9.39151 12.6951 8.94749 13.3211L9.67248 13.8353ZM10.9511 13.1739C11.4785 13.1739 11.9452 13.4342 12.2297 13.8353L12.9547 13.3211C12.5107 12.6951 11.7788 12.2851 10.9511 12.2851V13.1739ZM12.5686 14.0675C12.9417 14.0675 13.1832 13.6433 12.9547 13.3211L12.2297 13.8353C12.0281 13.5511 12.2421 13.1787 12.5686 13.1787V14.0675ZM9.33357 13.1787C9.6601 13.1787 9.87408 13.5511 9.67248 13.8353L8.94749 13.3211C8.71896 13.6433 8.96048 14.0675 9.33357 14.0675V13.1787Z"
                fill="#1D3B6D"
                mask="url(#path-4-outside-1_963_1204)"
              />
              <mask id="path-6-inside-2_963_1204" fill="white">
                <path d="M5.81881 11.0475C5.45848 10.9794 5.21794 10.6303 5.32687 10.2802C5.69028 9.11203 6.41667 8.08296 7.40882 7.348C8.5797 6.48063 10.0346 6.0867 11.4832 6.24483C12.9317 6.40295 14.2674 7.1015 15.2235 8.20105C16.1797 9.3006 16.6861 10.7203 16.6416 12.1768C16.5971 13.6332 16.0051 15.0194 14.9836 16.0585C13.9621 17.0977 12.5863 17.7134 11.1308 17.7828C9.67528 17.8523 8.24712 17.3703 7.13135 16.4331C6.1859 15.639 5.52366 14.5675 5.23222 13.3793C5.14486 13.0232 5.40626 12.6895 5.77007 12.6434C6.13388 12.5974 6.46131 12.8573 6.56078 13.2102C6.80223 14.067 7.29618 14.8372 7.98548 15.4162C8.84531 16.1384 9.94587 16.5099 11.0675 16.4564C12.1891 16.4029 13.2493 15.9284 14.0365 15.1276C14.8237 14.3268 15.28 13.2586 15.3142 12.1362C15.3485 11.0138 14.9583 9.91981 14.2214 9.07247C13.4846 8.22514 12.4553 7.68683 11.3391 7.56498C10.2228 7.44312 9.1016 7.74669 8.1993 8.4151C7.47595 8.95093 6.93592 9.68964 6.64264 10.53C6.52181 10.8763 6.17914 11.1157 5.81881 11.0475Z" />
              </mask>
              <path
                d="M5.81881 11.0475C5.45848 10.9794 5.21794 10.6303 5.32687 10.2802C5.69028 9.11203 6.41667 8.08296 7.40882 7.348C8.5797 6.48063 10.0346 6.0867 11.4832 6.24483C12.9317 6.40295 14.2674 7.1015 15.2235 8.20105C16.1797 9.3006 16.6861 10.7203 16.6416 12.1768C16.5971 13.6332 16.0051 15.0194 14.9836 16.0585C13.9621 17.0977 12.5863 17.7134 11.1308 17.7828C9.67528 17.8523 8.24712 17.3703 7.13135 16.4331C6.1859 15.639 5.52366 14.5675 5.23222 13.3793C5.14486 13.0232 5.40626 12.6895 5.77007 12.6434C6.13388 12.5974 6.46131 12.8573 6.56078 13.2102C6.80223 14.067 7.29618 14.8372 7.98548 15.4162C8.84531 16.1384 9.94587 16.5099 11.0675 16.4564C12.1891 16.4029 13.2493 15.9284 14.0365 15.1276C14.8237 14.3268 15.28 13.2586 15.3142 12.1362C15.3485 11.0138 14.9583 9.91981 14.2214 9.07247C13.4846 8.22514 12.4553 7.68683 11.3391 7.56498C10.2228 7.44312 9.1016 7.74669 8.1993 8.4151C7.47595 8.95093 6.93592 9.68964 6.64264 10.53C6.52181 10.8763 6.17914 11.1157 5.81881 11.0475Z"
                stroke="#1D3B6D"
                stroke-width="1.52984"
                mask="url(#path-6-inside-2_963_1204)"
              />
              <rect x="1" y="1" width="2" height="16" fill="#1D3B6D" />
            </svg>
          </span>
          {/* Add extra span */}
        </span>
      ),
      // sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ];

 
  const filterListData = [
    {
      type: "text",
      placeholder: "DC Agent Name",
      name: "name",
    },
    {
      type: "text",
      placeholder: "DC Agent ID",
      name: "agentId",
    },

    !isBranchAdmin && {
      type: "select",
      placeholder: "Branch Name",
      name: "branch_name",
      options: [...branchstate],
      api: global.getAllBranches,
      labelKey: "branch_name",
      valueKey: "branch_id",
      state: setBranchState,
    },
  ].filter(Boolean);

  const handleFilterReset = () => {
    setFilterData({
      agentId:"",
      agentName:"",
      branch_name:""
    });
    setErrorMsg({});
    setIsFilterReset(true);
  };
  
  useEffect(() => {
    if (isFilterReset) {
      setFilter(false);
      setIsFilterReset(false);
    } else {
      fetchTableDatas();
    }
  }, [isFilterReset, currentPage, pageSize]);
  

  const handleOnChange = (value, label, index, name) => {
    // console.log("Value:", value, "Label:", label, "Name:", name, "type:", type);
    let NameValue;
    if (name === "name")  {
      NameValue = preventSpecialCharsandNum(validateLength(value,30));
      setErrorMsg((pre) => ({
        ...pre,
        [name]: lengthValidation1(NameValue, 3),
      }));
      setFilterData((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "agentId") {
      NameValue = sanitizePhoneNumber2(validateLength(value,15));
      setFilterData((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "branch_name") {
      NameValue = value;
      setFilterData((prev) => ({ ...prev, [name]: value }));
    } else {
      NameValue = value;
    }
    setFilterData((prev) => ({ ...prev, [name]: NameValue }));
  };

  const fetchTableDatas =async (page) => {
    try {
      // debugger
      setIsLoader(true)
      let path;
      let reqstBody = {};
      let apiCalls = [];
      const agentIdValue = filterData?.agentId;
      const agentNameValue = filterData?.name;
      const branch_name = filterData.branch_name;
if(page){
  setcurrentPage(prev =>page)
}
      const startingIndex = (currentPage - 1) * pageSize + 1;
      let params = agentNameValue ? `name=${agentNameValue}&` : "";
      params = params + (agentIdValue ? `agentId=${agentIdValue}&` : "");
      params = params + (branch_name ? `branchId=${branch_name}&` : "");
      path = global.Reports + `?${params}page=${currentPage}&limit=${pageSize}`;
      // reqstBody = {};

      apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));

      const responses = await Promise.all(apiCalls);

      responses.forEach(({ message, response_data }) => {
        if (message === "Success") {
          const { data } = response_data || {};
          // console.log("Data....", data);
          setTableData(
            data.docs.map((itm, index) => ({
              ...itm,
              key: startingIndex + index,
              branchName: itm.branch.name,
            }))
          );
          setTotalCount(data.totalDocs);
          setpageSize(parseInt(data.limit));
        } else {
          // Handle error case if necessary
        }
      });
    } catch (error) {
      // console.log('error',error);
    } finally {
      setIsLoader(false);
    }
  }

  async function commonApiCall({ path, reqstBody, method = "post" }) {
    // console.log({ path, reqstBody });
    
    if(!path) return  
    try {
      const result = await ManageApis(method, path, reqstBody);
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const handleAction = (record) => {
    Navigate("/login_reports", {
      state: {
        agentId: record.agentId,
        agentName: record.name,
        branchName: record.branch.name,
      },
    });
  };


  const filterListOptions = async () => {
    try {
      let apiCalls = [];
      const filteredDataList = filterListData.filter((items) => items.name ==="branch_name");
      for (const filter of filterListData) {
        const apiUrl = filter.api;
        const path = apiUrl;
        let reqstBody = {};
        if (path)
          apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));
      }
      const responses = await Promise.all(apiCalls);

      responses.forEach(({ message, response_data }, index) => {
        const filter = filteredDataList[index];
        if (message === "Success") {
          const { data } = response_data || {};
          const mappedData =  data.map((item) => {
            return {
            label: item[filter.labelKey],
            value: item[filter.valueKey],
            all:item
          }});

          filter.state(mappedData);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };


  function onChangeTable(current, size) {
    setcurrentPage(current);
    setpageSize(parseInt(size));
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="bg-white  position-relative headStyle1 shadow_sm_4 w_fit_content min_w_160x ps-4 pt-3 pb-2">
          <h2 className="mb-0 mt-0 text-start text_master font_16 font_500">
            Reports
          </h2>
          <div className="headStyle2 position-absolute"></div>
        </div>
        <FilterComponent
          filterInputData={filterListData}
          handleChange={handleOnChange}
          value={filterData}
          errorMsg={errorMsg}
          populateBranchOptions={() => filterListOptions()}
          handleReset={handleFilterReset}
          handleApply={()=>{fetchTableDatas(1);setFilter(true)}}
          resetEnabled={filter}
        />
      </div>
      <div className=" pb-3  custom_pagination expandable-table customise_table2 shadow_sm_4   ">
        <DataTable
          columns={columns}
          rowData={TableData}
          pageSize={pageSize}
          currentPage={currentPage}
          onChangeTable={(current, Size) => onChangeTable(current, Size)}
          scroll={TotalCount > 5 ? { y: 400 } : ""}
          total={TotalCount}
          setPageSize={setpageSize}
        />
      </div>
    </>
  );
}

export default Reports;
