import React, {useContext, useEffect, useState } from "react";
import InputField from "../../Components/InputField/InputField";

import { useLocation, useNavigate } from "react-router-dom";
import { ManageApis } from "../../AppServices/ManageApi";
import DataTable from "../../Components/DataTable/DataTable";
import moment from "moment";
import { globalContext } from "../../App";
import { splitDeviceBrand } from "../../Common/Utils/Utils";
function LoginReports({ route }) {
  const location = useLocation();
  const { state } = location || {};
  // const {agentId}=agentId
  // console.log(state);
  const [TableData, setTableData] = useState([]);

  const [pageSize, setpageSize] = useState(10);
  const [TotalCount, setTotalCount] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [Date,setDate]=useState([])

  const [errorMsg, seterrorMessage] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const { setIsLoader } = useContext(globalContext);
  const Navigate = useNavigate();
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  const columns2 = [
    {
      title: "Sl No",
      dataIndex: "key",
    },
    {
      title: "Date",
      dataIndex: "created_date",
    },
    {
      title: "Device Details",
      dataIndex: "device_details",
      render: (text, record, index) => {
        return (
          <div>
            {Object.entries(record.device_details).map(([key, value]) => (
              <div key={key}>
                 <strong>{splitDeviceBrand(key)}:</strong> {value}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
    },
    {
      title: "Login Time",
      dataIndex: "Time",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
  ];

 
  useEffect(() => {
    fetchTableDatas();
  }, [currentPage, pageSize]);


  useEffect(()=>{
  if(Date.length === 0){
  setIsFilterApplied(prev=>true)
  } else {
    setIsFilterApplied(prev=>false)
  }
  },[Date])

  const fetchTableDatas =async () => {
    try {
      setIsLoader(true)
      let path =
        global.ReportsLogs + `?agentId=${state?.agentId}&page=${currentPage}&limit=${pageSize}`;
      let reqstBody = {};
      let apiCalls = [];
      const startingIndex = (currentPage - 1) * pageSize + 1;
      // debugger
      apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));

      const responses = await Promise.all(apiCalls);

      responses.forEach(({ message, response_data }) => {
        if (message === "Success") {
          const { data } = response_data || {};
          // console.log("data", data.docs);
          setTableData(
            data.docs.map((itm, index) => ({
              ...itm,
              key: startingIndex + index,
              created_date: itm.created_date
                ? moment(itm.created_date).format("DD-MM-YYYY")
                : "",
              Time: itm.created_date
                ? moment(itm.created_date).format("hh:mm A")
                : "",
            }))
          );
          setTotalCount(data.total);
          setIsLoader(false)
        } else {
          // Handle error case if necessary
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

const dateFilter = async () => {
  setIsLoader(true);
  try {
    setcurrentPage(prev => 1);
    const startDateValue = Date?.[0];
    const endDateValue = Date?.[1];

    if (Object.keys(errorMsg).length=== 0) {
      let params = startDateValue ? `startDate=${startDateValue}&` : "";
      params = params + (endDateValue ? `endDate=${endDateValue}&` : "");

      let path =
        global.ReportsLogs +
        `?${params}agentId=${state?.agentId}&page=${currentPage}`;
      let reqstBody = {};
      let apiCalls = [];

      apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));

      const responses = await Promise.all(apiCalls);

      responses.forEach(({ message, response_data }) => {
        if (message === "Success") {
          const { data } = response_data || {};
          setTableData(
            data.docs.map((itm, index) => ({
              ...itm,
              key: index + 1,
              created_date: itm.created_date
                ? moment(itm.created_date).format("DD-MM-YYYY")
                : "",
              Time: itm.created_date
                ? moment(itm.created_date).format("hh:mm A")
                : "",
            }))
          );
          setTotalCount(data.total);
          setIsFilterApplied(true);
        } else {
          // Handle error case if necessary
        }
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    setIsLoader(false);
  }
};


  async function commonApiCall({ path, reqstBody, method = "post",resp_type }) {
    if(!path) return 
    try {
      const result = await ManageApis(method, path, reqstBody,"",resp_type);
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const handleAction = () => {
    Navigate("/reports");
  };

  function onChangeTable(current, pageSize) {
    setcurrentPage(current);
    setpageSize(parseInt(pageSize));
  }

  const handleChange = (value, name) => {
    // debugger
    if(name ==="Date"){
      setDate(value)
    }
  };

  async function downloadPDF() {
  // debugger
    setIsLoader(true);
  
    try {
      const path = `${global.baseUrl}${global.downloads}?agentId=${state?.agentId}`;
      const reqstBody = {};
      const method = "get";
      const header={
        "Content-Type": "application/pdf",
      }

  
      const result = await commonApiCall({ path, reqstBody, method,header,resp_type:"blob"});
  
      if (result.message === "Success") {
        const response = result.response_data;
        const url = URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = "LoginReports.pdf";
        document.body.appendChild(a);
        a.click();
      } else {
        console.error("Error downloading PDF:", result.message);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setIsLoader(false);
    }
  }
  
  const resetFilter = () => {
    setIsFilterApplied(false);
    setDate(prev=>[])
    seterrorMessage({});
    fetchTableDatas();
  };


  return (
    <>
      <div className="mt-3">
        <div className="fw_600 font_18 text_master ms-2 mb-0">
          <div  onClick={handleAction} className="round_sm d-flex me-2 justify-content-center align-items-center bg_gray6 rounded-circle cursor-pointer d-inline-flex px_hover_1">
            <i class="fas fa-chevron-left"></i>
          </div>
          Reports
        </div>

        <div className="d-flex bg_gray11 p-3 mt-3">
          <div className="col-4">
            <div>
              <h2 className="font_13 font_400 text-black">DC Agent ID</h2>
              <h1 className="text_master font_500 font_14 mb-0">
                {state?.agentId}
              </h1>
            </div>
          </div>
          <div className="col-4">
            <div>
              <h2 className="font_13 font_400 text-black">DC Agent Name</h2>
              <h1 className="text_master font_500 font_14 mb-0">
                {state?.agentName}
              </h1>
            </div>
          </div>
          <div className="col-4">
            <div>
              <h2 className="font_13 font_400 text-black">Branch</h2>
              <h1 className="text_master font_500 font_14 mb-0">
                {state?.branchName}
              </h1>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end px-2">
          <div className="d-flex flex-column pt-2 justify-content-end">
            <h2 className="text-black  mb-2 font_13 font_400"></h2>
            <div className="d-flex gap-2 justify-content-end">
              <div className="arco_custom_date">
                <InputField
                  required
                  type={"date-range"}
                  disabledDate={disabledDate}
                  name={"Date"}
                  onchange={(name, value) => handleChange(name, value)}
                  value={Date}
                  onClear={()=>resetFilter()}
                  // options={[1, 5, 8, 8]}
                />
              </div>
              <div className="">
                <button
                  className="btn btn-master  font_15 font_500 text-nowrap"
                  onClick={dateFilter}
                  disabled={isFilterApplied}
                >
                  Apply Filter
                </button>
              </div>
              <div className="d-none">
                <div
                  onClick={() => downloadPDF()}
                  className="border bd_master w_40x h_36x d-flex align-items-center justify-content-center cursor-pointer bg-white br_6"
                >
                  <i class="fas fa-download text_master"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" pb-3  custom_pagination customise_table2 expandable-table expandable-table2 shadow_sm_4   ">
        <DataTable
          columns={columns2}
          rowData={TableData}
          currentPage={currentPage}
          pageSize={pageSize}
          onChangeTable={(current, Size) => onChangeTable(current, Size)}
          total={TotalCount}
          scroll={TotalCount > 2 ? { y: 400 } : ""}
          setPageSize={setpageSize}
        />
      </div>
    </>
  );
}

export default LoginReports;
