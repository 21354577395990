import React from 'react';
import { Message, Modal, Skeleton } from "@arco-design/web-react"
import moment from "moment"
import CryptoJS from "crypto-js";


/** to store details in local storage
 * @param key key to set values
 * @param value actual value stored in key
 */
export const removeLocalData = async (key) => {
    try {
      await localStorage.removeItem(key);
      return true;
    } catch (e) {
      // saving error
      //console.log("error in removeing localStorage", e);
      return false;
    }
  };
  export const storeLocalData = async (key, value) => {
    try {
      await localStorage.setItem(key, value);
      return true;
    } catch (e) {
      // saving error
      console.log("error in setting localStorage", e);
      return false;
    }
  };

  export const getData = async (key) => {
    try {
      const value = await localStorage.getItem(key);
      // console.log("getdata",value);
      if (value !== null) {
        return value;
        // value previously stored
      }
    } catch (e) {
      return false;
      // error reading value
    }
  };

  export const getThisMonth = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const startdate = firstDayOfMonth;
    const enddate = lastDayOfMonth;
    return {
      startdate,
      enddate
    };
  }

  export const getToday = () => {
    const currentDate = new Date();
    const startdate = currentDate;
    return {
      startdate
    };
  }
  

 export function capitalizeWords(str) {
    return str.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
    });
}

export function splitDeviceBrand(brand) {
    // Check if the brand contains a comma
    if (brand.includes(',')) {
        return capitalizeWords(brand.split(',').join(' '));
    }
    // Check if the brand contains an underscore
    else if (brand.includes('_')) {
        return capitalizeWords(brand.split('_').join(' '));
    }
    else {
        return capitalizeWords(brand);
    }
}

const BlankValidation = (name, item) => {
    // alert('1')
    customLogger('blank validate', name, item);
    if (item === "" || item === null) {
        // customLogger(name.replaceAll('_', ' ') + ' cannot be empty')
        customLogger(name.replaceAll('_', ' ') + ' cannot be empty')
        //   showMessage(name + " cannot blank! ", "warning", "Blank Validation Failed", "top", "top-right", 5000)
        return 0
    } else {
        //   showMessage(name + " validation passed! ", "success", "Validation Success", "bottom", "bottom-left", 2000)
        return 1
    }
}

export const PreventNonNumericChar = (e) => {
    let array = ['e', '+', '-', '.', 'E']

    if (array.includes(e.key)) {
        e.preventDefault()
    }

}
export function downloadBase64PDF(base64String, fileName) {
    try {
        // Decode the base64 string to binary data
        const binaryData = atob(base64String);

        // Create a Uint8Array from the binary data
        const arrayBuffer = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            arrayBuffer[i] = binaryData.charCodeAt(i);
        }

        // Create a Blob object from the binary data
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

        // Create a URL for the Blob object
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element for download
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName;

        // Trigger a click event to initiate the download
        downloadLink.click();

        // Clean up by revoking the Blob URL
        URL.revokeObjectURL(blobUrl);
    } catch (err) {
        console.log(err);
        return false
    }
}
export const FormValidation = (data, validatingData) => {
    // customLogger("validating  Data ", data)
    let validation = 1;
    let failedItems = []
    for (let i = 0; i < validatingData.length; i++) {
        if (BlankValidation(validatingData[i], data[validatingData[i]])) {
            customLogger("Validation passed for ", validatingData[i])
        } else {
            failedItems.push(validatingData[i])
            validation = 0;
            // break;
        }
    }

    return { validation, failedItems };
}
// Remove special characters from numbers
export const sanitizedText = text => text.replace(/[^0-9]/g, '');


export const preventSpecialChars = value => value?.replace(/[^a-zA-Z0-9]/g, '');
export const preventSpecialCharsandAllowHyphen = value => value?.replace(/[^a-zA-Z0-9-]/g, '')
export const preventSpecialCharsandNum = value => value.replace(/[^a-zA-Z\s]/g, '')
export const preventSpecialCharsandChar = value => value.replace(/[^0-9]/g, '')
export const preventSpecialCharsandCharAllowSlash = value => value.replace(/[^0-9\/]/g, '')

export const isValidPanCardNo = (panCardNo) => {
    // Regex to check valid
    // PAN Number
    var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    return regpan.test(panCardNo);
};
export function getDateFromToday(diff = 18) {
    const today = new Date();
    const PastDate = new Date(today.getFullYear() - diff, today.getMonth(), today.getDate());
    return PastDate;
}
export function validateAlphanumeric(str, minLen, maxLen = false) {
    const alphanumericPattern = /^[a-zA-Z0-9]+$/; // regular expression pattern for alphanumeric characters only
    const len = str.length;
    let text = "";
    if (len < minLen) {
        text = "Required minimum 6 characters";
    }
    else if (!alphanumericPattern.test(str)) {
        text = "Username must be alphanumeric";
    }
    return text;
}

export const validatePassword = (text) => {
    let error = ''
    let passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    if (!text) {
        error = "Password cannot be blank.";
    } else if (!text.match(passwordRegex)) {
        error = "Enter a strong Password";
    }
    return error
}



export const splitCamelCase = (data) => {
    let outdata = data.replace(/([a-z])([A-Z])/g, "$1 $2");
    let str = firstCamelCase(outdata);
    return str;
};
export const firstCamelCase = (data) => {
    customLogger(data)
    let modifiedString = data.replace(/([A-Z])/g, ' $1');
    modifiedString = modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1);
    return modifiedString
    // data.charAt(0).toUpperCase() + data.slice(1);
}

export const validateEmail = (text) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emailRegex.test(text)) return ''
    else return 'Enter valid email'
};

export const validateLength = (text, length = 5) => text.slice(0, length).replace(/\s+/g, ' ')
export const preventNumSpecialChars = value => value.replace(/[^a-zA-Z\s]/g, '')
export const lengthValidation = (text, length) => {
    if (length) {
        return text.length ? text.length < length ? `Required minimum ${length}` : '' : 'Field is required'
    } else {
        return text?.length ? '' : 'Field is required'
    }
}
export const lengthValidation1 = (text, length) => {
    if (length) {
        return text.length ? text.length < length ? `Required minimum ${length}` : '' : ''
    } else {
        return text?.length ? '' : ''
    }
}

export function sanitizePhoneNumber(input) {
    // Remove any non-digit characters from the input

    return input.target.value = input.target.value?.replace(/\D/g, "");
}
export function sanitizePhoneNumber2(value) {
    // Remove any non-digit characters from the input

    return value = value?.replace(/\D/g, "");
}

export const preventFirstSpace = (e, value) => {
    if (e.key === ' ' && value.length === 0) {
        e.preventDefault(); // Prevent adding a space as the first character
    }
}

export function getAge(DOB) {
    var today = new Date();
    const parts = DOB.split('-'); // Split the string into parts
    const formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`;

    var birthDate = new Date(formattedDateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const formatDate = (date, fromformat, format) => {
    // let newDate = new Date(date)
    if (date) {
        if (fromformat) {
            const momentObj = moment(date, fromformat);
            const momentString = momentObj.format(format);
            customLogger({ format }, { momentString });
            return momentString;
        } else {
            const momentObj = moment(date);
            const momentString = momentObj.format(format);
            customLogger({ format }, { momentString });
            return momentString;
        }

    } else return ''

};

export const successPopup = (res) => {
    // Message.success(message)
    if (res?.response_data?.message) Message.success(res.response_data.message)
    else if (res?.message) Message.success(res.message)
    else Message.success(res)
}
// export const FailedPopup = (res) => {
//     if (res?.response_data?.message) Message.error(res.response_data.message)
//     else if (res?.message) Message.error(res.message)
//     else Message.error(res)
// }
export const FailedPopup = (res) => {
    Modal.error({
        title: 'Failed',
        content: (res?.response_data?.message) ? res.response_data.message : (res?.message) ? res.message : res,
        okButtonProps: {
            status: 'master',
        },
        okText: 'OK',
        wrapClassName: 'arco_modal_wrap',
        maskClosable: false,
        escToExit: false,
    });
}

export const ConfirmSuccessPopup = (res, onOk, type, onCancel, okText, cancelText) => {

    const modalConfig = {
        title: '',
        icon: ' ',
        content: (res?.response_data?.message) ? res.response_data.message : (res?.message) ? res.message : res,
        okButtonProps: {
            status: 'master',
        },
        onOk: onOk ? onOk : '',
        onCancel: onCancel ? onCancel : '',
        cancelText: cancelText ? cancelText : 'Cancel',
        okText: okText ? okText : 'OK',
        wrapClassName: 'arco_modal_wrap',
        maskClosable: false,
        escToExit: false,
    };

    if (type) {
        switch (type) {
            case 'confirm':
                Modal.confirm(modalConfig);
                break;
            default:
                Modal.info(modalConfig);
                break;
        }
    } else {
        Modal.info(modalConfig);
    }
}
export const JSONStringify = (value) => JSON.stringify(value)
export const JSONParse = (value) => isValidJSON(value) ? JSON.parse(value) : ''

export function isValidJSON(jsonString) {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        return false;
    }
}

export const getMonthsRange = (range = 3, startdate, enddate) => {
    var today = startdate || enddate || new Date();

    if (startdate) {
        var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        var endDate = new Date(today.getFullYear(), today.getMonth() + range, today.getDate());
    } else {
        var startDate = new Date(today.getFullYear(), today.getMonth() - range, today.getDate());
        var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }
    return {
        startDate,
        endDate
    };
}
// sessions 
export const setSessionStorage = (key, value) => {
    try {
        return localStorage.setItem(key, value)
    } catch (err) {
        return err
    }
}
export const getSessionStorage = (key) => {
    try {
        return JSONParse(localStorage.getItem(key))
    } catch (err) {
        return err
    }
}

export const objectToString = (obj) => {
    const result = {};

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key] = String(obj[key]);
        }
    }

    return result;
};

export const removeSessionStorage = (key, value) => {
    try {
        return localStorage.removeItem(key)
    } catch (err) {
        return err
    }

}
export const clearSessionStorage = () => {
    try {
        return localStorage.clear()
    } catch (err) {
        return err
    }
}

export const ErrorLogger = (status_code, endpoint, message) => {
    // customLogger("error log----------->", status_code, endpoint, message);
};
export const resetNavigation = (path) => window.location.replace(path);

export const dataTableInitialLoader = (columns) => {
    let initialLoader = []
    let obj = {}
    for (let i = 0; i < 5; i++) {
        for (const j of columns) {
            obj[j.dataIndex] = <Skeleton style={{ height: '20px' }} text={{ rows: 1 }} />
        }
        initialLoader.push(obj)
    }
    return initialLoader
}
export function createNewObjectsWithSelectedKeys(dataArray, selectedKeys) {
    const newArray = [];

    for (const oldObj of dataArray) {
        const newObj = {};

        for (const key of selectedKeys) {
            if (oldObj.hasOwnProperty(key)) {
                newObj[key] = oldObj[key];
            }
        }

        for (const item of oldObj.chittals) {
            for (const propKey in item) {
                if (selectedKeys.includes(propKey)) {
                    newObj[propKey] = item[propKey];
                }
            }
        }

        newArray.push(newObj);
    }

    return newArray;
}

export const generateNonce = (length) => {
    const characters = '0123456789abcdef';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}


function trimAfterCharacter(inputString, character) {
    var index = inputString.indexOf(character);
    return index !== -1 ? inputString.slice(0, index) : inputString;
}


// common function for console log
export const customLogger = (...message) => console.log(message);

export const findMinutesDiff = (date1, date2) => {
    const timestamp1 = date1.getTime()
    const timestamp2 = date2.getTime()
    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(timestamp1 - timestamp2)

    // Convert milliseconds to minutes (1 minute = 60,000 milliseconds)
    const minuteDifference = Math.floor(timeDifference / 60000);

    return minuteDifference
}

export const getTimeAgo = (timestamp) => {
    const currentTime = moment();
    const timeDifference = moment.duration(currentTime.diff(moment(timestamp)));
    const minutes = timeDifference.minutes();
    const seconds = timeDifference.seconds();
    let date = moment(timestamp).format();
    return moment(date).startOf('minutes').fromNow();

    if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `${seconds} seconds ago`;
    }
}

export function capitalizeFirstLetter(str) {
    // console.log({str})
    str = str?.toLowerCase()
    return str?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

}



//  ---------------- encrypt  function -----------//

export const formatingValue = (value, max = 10, regx = /[^a-zA-Z0-9\s]/g) => value.replace(regx, '').slice(0, max).trimStart()

// set to local storage
export function setLocalStorage(data) {
    for (const key of Object.keys(data)) {
        localStorage.setItem(key, storeEncryptedData(data[key]));
    }
}
export const Formatedropdown = (data) => {
    var newArray = [];

    // Loop through the original array and create a new object with the updated property name for each object
    for (var i = 0; i < data?.length; i++) {
        var newObj = {
            key: data[i].text,
            value: data[i].value,
        };
        newArray.push(newObj);
    }
    return newArray;
};


// get  local storage
export function getLocalStorage(key) {
    // console.log("qqqqqqqqq->>.", getDecryptedData(localStorage.getItem(key)));
    return getDecryptedData(localStorage.getItem(key));
}

// Function to encrypt and store the data
export const storeEncryptedData = (data, encryptionKey = "pixlsecret@2798") => {
    // //debugger
    const encryptedData = CryptoJS.AES.encrypt(
        data.toString(),
        encryptionKey
    ).toString();
    return encryptedData;
};

// Function to retrieve and decrypt the data
export const getDecryptedData = (encryptedData, encryptionKey = "pixlsecret@2798") => {
    if (encryptedData) {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    }
    return null;
};

// Function to derive key in React (JavaScript)
const deriveKey = (password) => {
    // Derive the key using PBKDF2
    const key = CryptoJS.PBKDF2(password, "", {
        hasher: CryptoJS.algo.SHA256,
        keySize: 128 / 32,
        iterations: 100000,
    });

    // Convert the key to a WordArray and return it
    return key;
};
// Function to generate random IV in React (JavaScript)
const generateIV = () => {
    return CryptoJS.lib.WordArray.random(128 / 8).toString();
};

// Function to encrypt in React (JavaScript)
export const encryptData = (text, password = "pix@123") => {
    const key = deriveKey(password);
    const iv = generateIV();
    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: CryptoJS.enc.Hex.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(iv + encrypted.ciphertext.toString()));
};

// Function to decrypt in React (JavaScript)
export const decryptData = (cipherText, password = "pix@123") => {
    // Derive the key using the provided password
    const key = deriveKey(password);

    // Decode the Base64-encoded ciphertext
    const ciphertextHex = CryptoJS.enc.Hex.parse(CryptoJS.enc.Base64.parse(cipherText).toString(CryptoJS.enc.Hex));

    // Extract the IV from the first 16 bytes of the ciphertext
    const iv = ciphertextHex.toString(CryptoJS.enc.Hex).substring(0, 32);

    // Extract the ciphertext excluding the IV
    const ciphertext = ciphertextHex.toString(CryptoJS.enc.Hex).substring(32);

    // Decrypt using the derived key and extracted IV
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Hex.parse(ciphertext) },
        key,
        { iv: CryptoJS.enc.Hex.parse(iv), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    // Convert the decrypted data to a UTF-8 string and return it
    return decrypted.toString(CryptoJS.enc.Utf8);
};
