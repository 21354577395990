import React, { useState } from 'react'
import BarChart from '../TransactionGraph/BarChart'
import dayjs from 'dayjs';
import { DatePicker } from '@arco-design/web-react';
import { getThisMonth} from '../../Common/Utils/Utils';
import moment from 'moment';
function MonthlyTransSummary() {
  
  const startOfMonth =getThisMonth();
  const currentDate = new Date();
  const startDate=moment(startOfMonth.startdate).format('DD-MM-YYYY')
  const endDate=moment(currentDate).format('DD-MM-YYYY')
  const defaultDateRange = [startDate, endDate];
  const [date, setDate] = useState(defaultDateRange);


const handleChange=(value)=>{
  setDate(prev =>value)
}

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
};

const handleClear=()=>{
    setDate(defaultDateRange);
}


  return (
    
    <div className=''>
        <h2 className='text-black fw_700 font_20'>Monthly transaction summary</h2>
        <div className='row mt-3'>
            <div className='col-12 mb-3'>
            <div className='bg-white shadow_sm_4 position-relative br_16 px-3 chartHead'>
              <div className=''>
                <BarChart dateRange={date}/>
              </div>
              
              <div className='position-absolute sort_box  px-0'>
                <div className='d-flex sort_label align-items-center'>
                  <h2 className='pe-2 font_14 w-100 text-center font_600 text-black'>Filter By</h2>
                   <div className='arco_select'>
                      {/* <InputField 
                        required 
                        type={'select'} 
                        name={'month'}
                        options={[]}
                        placeholder={"Months"}
                      /> */}

                                        
                   <DatePicker.RangePicker
                      style={{ width: 250 }}
                      format='DD-MM-YYYY'
                      shortcutsPlacementLeft
                      shortcuts={[
                       
                        {
                          text: '1 Month',
                          value: () => [dayjs(), dayjs().subtract(1, 'month')],
                        },
                        {
                          text: '6 Months',
                          value: () => [dayjs(), dayjs().subtract(6, 'month')],
                        },
                        
                      ]}
                      onClear={handleClear}
                      disabledDate={disabledDate} 
                      value={date}
                      onChange={(value,name)=>handleChange(value,name)}
      />
                   </div>
                </div>
                <div className='d-none'>
                  <div className='mb-3 d-flex align-items-center'>
                    <span className='w_10x d-inline-flex bg_master rounded-pill h_10x'></span>
                    <h2 className='mb-0 text-black font_13 font_400 ps-2'>Cash</h2>
                  </div>
                  <div className='d-flex align-items-center'>
                    <span className='w_10x d-inline-flex bg_master_d1 rounded-pill h_10x'></span>
                    <h2 className='mb-0 text-black font_13 font_400 ps-2'>Cheque</h2>
                  </div>
                </div>
              </div>

            </div>
            </div>
        </div> 
    </div>
  )
}

export default MonthlyTransSummary