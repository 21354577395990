import { Alert } from "react-bootstrap";
import { Stack } from "react-bootstrap";


function AlertBox({message='',show,handlecancel,enableSuccessIcon,enableFailIcon,children,btnLabel,hideCloseBtn}) {
  
    return (  
        
      
        <Alert show={show} className="px_alert alert_md alert_vcenter  ">
            
            <div className="px_alert_body rounded-3 w-100 m-auto max_h_100p overflow-auto bg-white ">
                <div className="m-3 pb-0 text-center">
               {enableSuccessIcon&& <i className="fa-check-circle fa-4x far mb-0 text_green1"/>}
               {enableFailIcon&& <i className="fas fa-times-circle fa-3x pb-0 text-danger"/>}
                    <p className="pt-3 mb-0">{message} </p>
                </div>
                    {children}
                <div className={`modal-footer bd_gray`}>
                    <Stack direction="horizontal" className="m-2 justify-content-center w-100" gap={2}>
                        <button className={`btn btn-master py-1 w_100x ${hideCloseBtn?"d-none":""} `} onClick={handlecancel} >{btnLabel||"Close"}</button>
                    </Stack>
                </div>
            </div>   
        </Alert>
    );
  }
  
  export default AlertBox;