import React, { useCallback, useContext, useEffect, useState } from "react";
import InputField from "../../Components/InputField/InputField";
import ModalComponent from "../../Components/ModalComponent/ModalComponent";
import AlertBox from "../../Components/AlertBox/AlertBox";
import img1 from "../../assets/images/calendar-cells.svg";
import {
  lengthValidation,
  sanitizePhoneNumber,
  sanitizePhoneNumber2,
  validateLength,
} from "../../Common/Utils/Utils";
import LimitCard from "../../Components/ModalComponent/limitCard";
import { ManageApis } from "../../AppServices/ManageApi";
import { globalContext } from "../../App";

function Settings() {
  const AlertinitialValue = {
    show: false,
    type: "",
    message: "Mobile Number Changed Successfully",
    isSuccess: true,
    isFalse:true,
  };

  const initialVisibleValue = { show: false, type: "" };

  const [visible, setvisible] = useState(initialVisibleValue);
  const [showAlert, setShowAlert] = useState(AlertinitialValue);
  const [number, setNumber] = useState({
    oldNumber: "",
    NewNumber: "",
  });
  const [cashLimit, setCashLimit] = useState();
  const [chequeLimit, setChequeLimit] = useState();
  const [errorMsg, seterrorMessage] = useState({});
  const [agentIds, setAgentIds] = useState([]);
  const [agent, setAgent] = useState({});
  const [collectionLimit,setCollectionLimit]=useState()
  const handleModalClose = () => {
    setvisible(initialVisibleValue);
    handlelimit();
    // setRowData({})
  };
  const [clickedReset, setClickedReset] = useState(true)
  const [actualOldNumber, setActualOldNumber] = useState("");
  const [havePermission,setHavePermission]=useState({
    PhoneNumber:"",
    Limit:"",
    BlockCollection:""
  })
  const { setIsLoader,userPermissions } = useContext(globalContext);

  useEffect(() => {
    fetchagentId();
    handlelimit();
    setAgent(null);
    setClickedReset(!clickedReset)
  }, []);


  useEffect(() => {
    const UpdateNumber = userPermissions.some(
      permission => permission.label === 'UpdateMobileNumber'
    );
  
    const UpdateLimit = userPermissions.some(
      permission => permission.label === 'UpdateDailyLimit'
    );
    const BlockCollection = userPermissions.some(
      permission => permission.label === 'CollectionBlockSettings'
    );
  
    setHavePermission({
      PhoneNumber: UpdateNumber,
      Limit: UpdateLimit,
      BlockCollection:BlockCollection
    });
  }, [userPermissions]);
  

  const fetchagentId = async () => {
    try {
      setIsLoader(true);
      let apiCalls = [];
      const path = global.getAgentList + `?getAll=${true}`;
      let reqstBody = {};
      apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));
      const responses = await Promise.all(apiCalls);
      //  console.log(responses);
      responses.forEach(({ message, response_data }, index) => {
        if (message === "Success") {
          const { data } = response_data || {};
          // console.log("FILTERDATA.......", data);
          setAgentIds(
            data.map((item) => ({
              label: `${item.name} (${item.agentId})`,
              value: item.agentId,
              mobileNo: item.mobileNo,
            }))
          );
          const agentData = data?.docs?.map((item) => ({
            mobileNo: item.mobileNo,
          }));
          setActualOldNumber(agentData);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  async function commonApiCall({ path, reqstBody, method = "post" }) {
    if(!path) return 
    try {
      const result = await ManageApis(method, path, reqstBody);
      // console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const handleButtonClick = async () => {
    if (number.NewNumber.length !== 10) {
      setShowAlert({
        show: true,
        type: "",
        message: "Invalid mobile number",
        isFalse:true,
      });
    } else if (String(number.oldNumber) === String(number.NewNumber)) {
      setShowAlert({
        show: true,
        type: "",
        message: "Latest phone number should be not same as previous number",
        isFalse:true,
      });
    } else {
      await handleUpdate();
    }
    setClickedReset(true);
    setAgent(null)
    setNumber({ oldNumber: "", NewNumber: "" });
  };

  //Phone Number Updation
  const handleUpdate = async () => {
    setIsLoader(true);
    try {
      const { oldNumber, NewNumber } = number;
      const requestBody = {
        agentId: agent,
        currentMobile: oldNumber,
        newMobile: parseInt(NewNumber),
      };

      const response = await commonApiCall({
        path: global.numUpdate,
        reqstBody: requestBody,
        method: "put",
      });

      if (response?.response_data?.status) {
        setShowAlert({
          show: true,
          type: "",
          message: response.message,
          isSuccess: true,
        });
      } else {
        setShowAlert({
          show: true,
          type: "",
          message: response.response_data.message,
          isFalse: true,
        });
      }  
      fetchagentId()
      setClickedReset(prev =>true)
    } catch (error) {
      setShowAlert({
        show: true,
        type: "",
        message: "Error updating mobile number: " + error.message,
        isFalse: true,
      });
    } finally {
      setIsLoader(false);
    }
  };

  const handleChangeLimit = async () => {
    try {
      const requestBody = {
        collection_limit: {
          cash: Number(cashLimit),
          cheque: Number(chequeLimit),
        },
      };

      const response = await commonApiCall({
        path: global.updateLimit,
        reqstBody: requestBody,
        method: "put",
      });

      if (response?.response_data?.status) {
        setShowAlert({
          show: true,
          type: "",
          message: response.response_data.message,
          isSuccess: true,
        });
      } else {
        setShowAlert({
          show: true,
          type: "",
          message: response.response_data.message,
          isFalse: true,
        });
      }
      fetchagentId()
      setvisible(initialVisibleValue);
    } catch (error) {
      console.error("Error updating daily limit", error);
    }
  };
  
  const UpdateCollectionLimit = async () => {
    try {
      const requestBody = {
       "installmentBlockLimit":collectionLimit
      };

      const response = await commonApiCall({
        path: global.collectionLimit,
        reqstBody: requestBody,
        method: "put",
      });

      if (response?.response_data?.status) {
        setShowAlert({
          show: true,
          type: "",
          message: response.response_data.message,
          isSuccess: true,
        });
        setAgent({})
      } else {
        setShowAlert({
          show: true,
          type: "",
          message: response.response_data.message,
          isFalse: true,
        });
      }
      fetchagentId()
      setvisible(initialVisibleValue);
    } catch (error) {
      console.error("Error updating daily limit", error);
    }
  };

  const handlelimit = async () => {
    try {
      setIsLoader(true)
      let apiCalls = [];
      const path = global.FetchLimit;
      let reqstBody = {};
      apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));
      const responses = await Promise.all(apiCalls);
      responses.forEach(({ message, response_data }, index) => {
        if (message === "Success") {
          const { data } = response_data || {};
          // console.log("Data.....", data.cash);
          setCashLimit(data.cash);
          setChequeLimit(data.cheque);
          setCollectionLimit(data?.installmentBlockLimit)
        } else {
        }
      });
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoader(false)
    }
  };

  const handleOnchange = (event, name, type, mobileNo) => {
    // console.log("Event===>", event, "Name===>", name, "Type===>", type);
    let numValue;
    if (type === "text") {
        numValue = sanitizePhoneNumber2(validateLength(event, 10));
        seterrorMessage((pre) => ({
            ...pre,
            [name]: lengthValidation(numValue, 10),
        }));
        setNumber((prev) => ({ ...prev, [name]: numValue }));
    } else if (type === "select") {
      if (!event) {
        setNumber({
            oldNumber: "",
            NewNumber: ""
        });
        setAgent(null);
    } else {
        const selectedAgent = agentIds.find((agent) => agent.value === event);
        if (selectedAgent) {
            setNumber((prev) => ({
                ...prev,
                oldNumber: selectedAgent.mobileNo,
            }));
        }
        setAgent(event);
    }
  } else if (name === "cash" || name === "cheque" || name==="collection") {
    numValue = sanitizePhoneNumber2(validateLength(event, 10));
    if (name === "cash") {
        setCashLimit(numValue);
    } else if (name === "cheque") {
        setChequeLimit(numValue);
    }else {
      setCollectionLimit(numValue)
    }
}
};


  const Modaldata = [
    { title: "Daily cash limit", name: "cash", type: "cash", value: cashLimit },
    {
      title: "Daily cheque limit",
      name: "cheque",
      type: "cheque",
      value: chequeLimit,
    },
  ];

  const handleEditModalOpen = (title, type) => {
    setvisible({ show: true, title: title, type: type });
  };

  return (
    <div>
      <AlertBox
        show={showAlert.show}
        message={showAlert.message}
        enableFailIcon={showAlert.isFalse}
        enableSuccessIcon={showAlert.isSuccess}
        handlecancel={() => setShowAlert(AlertinitialValue)}
        hideCloseBtn={showAlert.hideCloseBtn}
      />
      <div className="bg-white position-relative headStyle1  w_fit_content min_w_160x ps-4 pt-3 pb-2">
        <h2 className="mb-0 mt-0 text-start text_master font_18 font_500">
          Settings
        </h2>
        <div className="headStyle2 position-absolute"></div>
      </div>
      <div className="shadow_sm_4 bg-white  pt-3">
        <div className="bd_master2_d1  rounded mb-3">
          <div className="bg_light2 rounded py-1">
            <h3 className="text_master mb-0 px-3 py-2 font_15 fw_600">
            Change DC Agent Phone Number
            </h3>
          </div>
          <div className="row mt-3 px-2">
            <div className="col-12 col-md-3">
              <div className="arco_select w-100">
                <InputField
                  required
                  name={"agentId"}
                  type={"select"}
                  placeholder={"Select Agent"}
                  options={[...agentIds]}
                  onchange={(event) => handleOnchange(event, "agent", "select")}
                  reset={clickedReset}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="arco_select w-100">
                <InputField
                  required
                  type={"text"}
                  placeholder={"Old Phone Number"}
                  name={"OldPhoneNumber"}
                  value={number.oldNumber}
                  seterrorMessage={(name, message) => {
                    console.log(name, message);
                  }}
                  // errorMessage={errorMsg["OldPhoneNumber"]}
                  onchange={(event) =>
                    handleOnchange(event, "oldNumber", "text")
                  }
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="arco_select w-100">
                <InputField
                  required
                  disabled={!agent}
                  type={"text"}
                  name={"NewPhoneNumber"}
                  value={number.NewNumber}
                  seterrorMessage={(name, message) =>
                    console.log(name, message)
                  }
                  onchange={(event) =>
                    handleOnchange(event, "NewNumber", "text")
                  }
                  placeholder={"Latest Phone Number"}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <button
                className="btn btn-master w_fit_content px-4 font_14 font_500 ms-2 "
                onClick={handleButtonClick}
                disabled={!havePermission.PhoneNumber || !number.NewNumber} 
              >
                Change Mobile Number
              </button>
            </div>
          </div>
        </div>
        <div className="bd_master2_d1  rounded mb-3">
          <div className="bg_light2 rounded py-1">
            <h3 className="text_master mb-0 px-3 py-2 font_15 fw_600">
            Default Collection Limit
            </h3>
          </div>
          <div className="row mt-3 px-2">
            <div className="col-12 col-md-3">
              <div className="arco_select w-100">
                <InputField
                  required
                  type={"number"}
                  name={"CollectionLimit"}
                  value={collectionLimit}
                  seterrorMessage={(name, message) =>
                    console.log(name, message)
                  }
                  onchange={(event) =>
                    handleOnchange(event, "collection", "number")
                  }
                  placeholder={"Default Collection Limit"}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <button
                className="btn btn-master w_fit_content px-4 font_14 font_500 ms-2 "
                onClick={UpdateCollectionLimit}
                disabled={!havePermission.BlockCollection || !collectionLimit} 
              >
                Update Limit
              </button>
            </div>
          </div>
        </div>

        <div className="bd_master2_d1  rounded mb-3">
          <div className="bg_light2 rounded py-1">
            <h3 className="text_master mb-0 px-3 py-2 font_15 fw_600">
              Cash Limit and Cheque Limit
            </h3>
          </div>
          <div className="row pt-3 ps-3 pb-4">
            {/*  */}
            {Modaldata.map((item, index) => (
              <div className="col-12 col-md-4 mb-3">
                <LimitCard
                  key={index}
                  title={item.title}
                  limit={item.value}
                  imgSrc={img1}
                  disabled={!havePermission.Limit}
                  onEdit={() => handleEditModalOpen(item.title, item.type)}
                />
              </div>
            ))}

          </div>
        </div>
      </div>

      {Modaldata.map((item, index) => (
        <ModalComponent
          key={index}
          visible={
            visible.show &&
            visible.title === item.title &&
            visible.type === item.type
          }
          title={item.title}
          size="md"
          handleClose={handleModalClose}
        >
          <div>
            <h2 className="text-black font_600 font_17 mb-3">{item.title}</h2>
            <InputField
              required
              type={"number"}
              name={item.name}
              placeholder={"Enter Daily Limit"}
              seterrorMessage={(name, message) => console.log(name, message)}
              onchange={(event, name, type) =>
                handleOnchange(event, name, type)
              }
              value={item.value}
            />
            <div className="text-end">
              <button
                className="btn font_15 font_500 px-3 text_master"
                onClick={handleModalClose}
              >
                Cancel
              </button>
              <button
                onClick={handleChangeLimit}
                disabled={!cashLimit || !chequeLimit}
                className="btn btn-master ms-2 font_15 font_500 px-5"
              >
                Apply
              </button>
            </div>
          </div>
        </ModalComponent>
      ))}
    </div>
  );
}

export default Settings;
