import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ percentage,color }) => {
  return (
    <div className='position-relative' style={{ width: '150px'}}>
        
        <CircularProgressbar
        value={percentage}
        styles={buildStyles({
          textSize: 0, // Hide the percentage text
          pathColor: color || '#1D3B6D', // Use the provided color or default to '#1D3B6D'
          trailColor: '#e74432',
        })}
      />
    </div>
  );
};

export default CircularProgressBar;
