import React from 'react';
import { Modal } from 'react-bootstrap';

function ModalComponent({ visible, children, title, size, handleClose, backdrop, type }) {
  return (
    <Modal show={visible} onHide={handleClose} backdrop={backdrop} centered size={size}>
      <div className='custom_modal'>
        <Modal.Header style={{justifyContent:'space-between'}} closeButton={type !== "Accounts List"}>
          <div></div>
          <Modal.Title className='h6 text-white'>
            {title}
          </Modal.Title>
        </Modal.Header>
      </div>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default ModalComponent;
