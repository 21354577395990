import React, { useContext, useEffect, useState } from 'react'
import Header from '../Header/Header'
import SidebarMenu from '../Sidebar/Sidebar'
import Dashboard from '../../Pages/Dashboard/Dashboard'
import Agents from '../../Pages/Agents/Agents'
import Collections from '../../Pages/Collections/Collections'
import Reports from '../../Pages/Reports/Reports'
import Loader from '../Loader/Loader'
import LoginReports from '../../Pages/LoginReports/LoginReports'
import Settings from '../../Pages/Settings/Settings'
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom'
import { RouteList, getroutes } from '../../Common/Constant'
import { globalContext } from '../../App';
import ErrorPage from '../../Pages/404Page/ErrorPage'
function LayoutMain() {
  const { pathname } = useLocation()

  const { isLoader, user } = useContext(globalContext);

  const userRoutes = getroutes(user)

  return (
    <div className='vh-100 bg_light4 overflow-hidden'>
      {isLoader && <Loader />}

      <div className='mx-0 h-100 row'>
        <div className='col-2 px-0'>
          <SidebarMenu />
        </div>
        <div className={`col-10 px-0  `}>

          <Header />
          <div className='p-4'>

            <Routes>

              {userRoutes.map((item, index) => (

                <>
                  {item.route}
                </>

              ))}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
        </div>


      </div>
              
        <h2 className="font_10 mb-0 versionText">Version {global.version}</h2>
    </div>
  )
}

export default LayoutMain