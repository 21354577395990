
import React, {useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ModalComponent from "../../Components/ModalComponent/ModalComponent";
import DataTable from "../../Components/DataTable/DataTable";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import { ManageApis } from "../../AppServices/ManageApi";
import {
  lengthValidation1,
  preventSpecialCharsandNum,
  sanitizePhoneNumber2,
  validateLength,
} from "../../Common/Utils/Utils";

import { globalContext } from "../../App";
import moment from "moment";
function Agents() {
  const [selectedTabs, setSelectedTabs] = useState("DCAgents");
  const [DCAgentTableVisible, setDCAgentTableVisible] = useState(true);
  const [DCAgentBlockTable, setDCAgentBlockTable] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [TableData, setTableData] = useState([]);
  const [pageSize, setpageSize] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const initialVisibleValue = { show: false, type: "" };
  const [visible, setvisible] = useState(initialVisibleValue);
  const [errorMsg, setErrorMsg] = useState({});
  const [branchState, setBranchState] = useState([]);
  const [RegionState, setRegionState] = useState([]);
  const [isExpand, setisExpand] = useState("");
  const [RecordValue, setRecordValue] = useState({});
  const [filterVal,setFilterVal]=useState({
    agentName:"",
    mobileNo:"",
    agentId:"",
    branch_name:"",
region_name:""
  })
  const [isFilterReset, setIsFilterReset] = useState(false);
  const [filter,setFilter]=useState(false)
  const previousTab = useRef(selectedTabs);
  const [isBlockAgentAllowed, setIsBlockAgentAllowed] = useState(false);

  const { setIsLoader,userPermissions,isBranchAdmin } = useContext(globalContext);

useEffect(() => {
  const hasBlockAgentPermission = userPermissions.some(
    permission => permission.label === 'AgentBlock'
  );

  setIsBlockAgentAllowed(hasBlockAgentPermission);
}, [userPermissions]);


  //Active DC Agents
  const ActiveAgents = [
    {
      title: "Sl No",
      dataIndex: "key",
      render: (text) => text || "NA",
      sorter: (a, b) => {
        if (a.key > b.key) {
          return 1;
        }
        if (a.key < b.key) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'110px'
    }
    },

    {
      title: "DC Agent Name",
      dataIndex: "name",
      render: (text, record, index) =>
        (
          <span className="d-flex flex-column">
            {text} {/* Display the original text */}
            <span
              className="mt-1 w_fit_content active_span rounded-pill font_14 px-2 text_master2_d1 rounded"
              style={{
                backgroundColor: index % 2 === 0 ? "white" : "#F3F5F7",
                /* Apply different background colors based on index */
              }}
            >
              <span className="w_10x h_10x bg_master_d1 me-1 d-inline-block rounded-pill"></span>
              Active
            </span>
            {/* Add extra span */}
          </span>
        ) || "NA",
      sorter: (a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'190px'
    }

    },
    {
      title: "DC Agent ID",
      dataIndex: "agentId",
      sorter: (a, b) => {
        if (a.agentId > b.agentId) {
          return 1;
        }
        if (a.agentId < b.agentId) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'150px'
    },

      render: (text) => text || "NA",
    },
    {
      title: "Branch",
      dataIndex: "branchName",
      sorter: (a, b) => {
        if (a.branchName > b.branchName) {
          return 1;
        }
        if (a.branchName < b.branchName) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
      headerCellStyle: {
        minWidth:'180px'
    }
    },
    {
      title: "Region",
      dataIndex: "RegionName",
      sorter: (a, b) => {
        if (a.RegionName > b.RegionName) {
          return 1;
        }
        if (a.RegionName < b.RegionName) {
          return -1;
        }
        return 0;
      },
      render: (text) => text || "NA",
      headerCellStyle: {
        minWidth:'150px'
    }
    },
    {
      title: "Contact Number",
      dataIndex: "mobileNo",
      render: (text) => text || "NA",
        sorter: (a, b) => {
        if (a.mobileNo > b.mobileNo) {
          return 1;
        }
        if (a.mobileNo < b.mobileNo) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'190px'
    }
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) =>
        (
          <Button
          disabled={!isBlockAgentAllowed}
            className="btn button1 text_master border-0 px-4 py-0 rounded-pill font_500  shadow_sm_4 font_14  px-4"
            style={{
              backgroundColor:
                index % 2 === 0 ? "#fff" : "rgba(29, 59, 109, 0.10)",
            }}
            onClick={() => handleAction(record)}
          >
            Block
          </Button>
        ) || "NA",
      // sorter: (a, b) => a.name.localeCompare(b.name),
      headerCellStyle: {
        minWidth:'120px'
    }
    },
    {
      title: "",
      dataIndex: "",
      render: (text, record, index) => {
        return (
          <div>
            {isExpand == record._id ? (
              <i className="fas fa-chevron-up text_master"></i>
            ) : (
              <i className="fas fa-chevron-down text_master"></i>
            )}
          </div>
        );
      },
    },
  ];

  //Expansion Data
  const ExpandedData = [
    // {
    //   title: "Contact No.",
    //   dataIndex: "mobileNo",
    //   render: (text) => text || "NA",
    // },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      render: (text) => text || "NA",
    },
  ];

  //Blocked DC Agents
  const BlockedDCAgents = [
    {
      title: "Sl No",
      dataIndex: "key",
      render: (text) => text || "NA",
      sorter: (a, b) => {
        if (a.key > b.key) {
          return 1;
        }
        if (a.key < b.key) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'110px'
    }
    },

    {
      title: "DC Agent Name",
      dataIndex: "name",
      render: (text, record, index) =>
        (
          <span className="d-flex flex-column">
            {text} {/* Display the original text */}
            <span
              className="mt-1 w_fit_content active_span rounded-pill font_14 px-2 text-danger rounded"
              style={{
                backgroundColor: "#f9e1e2",
                /* Apply different background colors based on index */
              }}
            >
              <span
                style={{ background: "#e74432" }}
                className="w_10x h_10x  me-1 d-inline-block rounded-pill"
              ></span>
              Inactive
            </span>
          </span>
        ) || "NA",
      sorter: (a, b) => a.name.localeCompare(b.name),
      headerCellStyle: {
        minWidth:'190px'
    }
    },
    {
      title: "DC Agent ID",
      dataIndex: "agentId",
      render: (text) => text || "NA",
      sorter: (a, b) => {
        if (a.agentId > b.agentId) {
          return 1;
        }
        if (a.agentId < b.agentId) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'190px'
    }
    },
    {
      title: "Branch",
      dataIndex: "branchName",
      render: (text) => text || "NA",
      sorter: (a, b) => {
        if (a.branchName > b.branchName) {
          return 1;
        }
        if (a.branchName < b.branchName) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'190px'
    }
    },
    {
      title: "Region",
      dataIndex: "RegionName",
      render: (text) => text || "NA",
      sorter: (a, b) => {
        if (a.RegionName > b.RegionName) {
          return 1;
        }
        if (a.RegionName < b.RegionName) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'150px'
    }
    },
    {
      title: "Contact Number",
      dataIndex: "mobileNo",
      render: (text) => text || "NA",
        sorter: (a, b) => {
        if (a.mobileNo > b.mobileNo) {
          return 1;
        }
        if (a.mobileNo < b.mobileNo) {
          return -1;
        }
        return 0;
      },
      headerCellStyle: {
        minWidth:'190px'
    }
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record, index) =>
        (
          <Button
          disabled={!isBlockAgentAllowed}
            className="btn button1 text_master border-0 px-4 py-0 rounded-pill font_500 shadow_sm_4 font_14  px-4"
            style={{
              backgroundColor:
                index % 2 === 0 ? "#fff" : "rgba(29, 59, 109, 0.10)",
            }}
            onClick={() => handleAction(record)}
          >
            UnBlock
          </Button>
        ) || "NA",
      headerCellStyle: {
        minWidth:'110px'
    }
    },
    {
      title: "",
      dataIndex: "key",
      render: (text, record, index) => {
        return (
          <div>
            {isExpand == record._id? (
              <i className="fas fa-chevron-up text_master"></i>
            ) : (
              <i className="fas fa-chevron-down text_master"></i>
            )}
          </div>
        );
      },
    },
  ];


  const handleTabChange = (tab) => {
    if (tab === selectedTabs) {
      return;
    }
    setTableData([]);
    setSelectedTabs(tab);
 setisExpand(prev =>"")
    if (tab === "DCAgents") {
      setDCAgentTableVisible(true);
      setDCAgentBlockTable(false);
    } else if (tab === "DCAgentsBlocked") {
      setDCAgentTableVisible(false);
      setDCAgentBlockTable(true);
    }
    handleFilterReset()
  };
  
  

  const handleModalClose = () => {
    setvisible(initialVisibleValue);
    // setRowData({})
  };
  const handleModalClose2 = () => {
    setvisible(initialVisibleValue);
  };

  const handleAction = (record) => {
    setvisible({ show: true });
    setRecordValue(record);
  };

  function expandedRowRender(record) {
    const filteredData = TableData.filter((item) => item.key === record.key);
    return (
      <>
        <div
          style={{ width: "49%", paddingLeft: "130px" }}
          className="expand_inner_table "
        >
          <DataTable rowData={filteredData} columns={ExpandedData} />
        </div>
      </>
    );
  }

  const handleOnChange = (value, label, index, name) => {
    // console.log('value',value,'label',label,'name',name,'index',index);
    let NameValue;
    if (name === "agentName")  {
      NameValue = preventSpecialCharsandNum(validateLength(value,30));
      setErrorMsg((pre) => ({
        ...pre,
        [name]: lengthValidation1(NameValue, 3),
      }));
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "agentId") {
      NameValue =sanitizePhoneNumber2(validateLength(value,15));
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "mobileNo") {
      NameValue = sanitizePhoneNumber2(validateLength(value, 10));
      setErrorMsg((pre) => ({
        ...pre,
        [name]: lengthValidation1(NameValue, 10),
      }));
      setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
    } else if (name === "branch_name") {
      // console.log('value',index);
      if(value === branchState[index]?.all?.branch_id){
        let val=branchState[index]?.all?.parent_branch?.region_code
        setFilterVal((prev) => ({ ...prev, ["region_name"]: val }));
      }
      NameValue = value;
      setFilterVal((prev) => ({ ...prev, [name]: value }));
    } 
    else if (name === "region_name") {
      NameValue = value;
      setFilterVal((prev) => ({ ...prev, [name]: value }));
      filterSection(name,value);
    } 
    else {
      NameValue = value;
    }
    setFilterVal((prev) => ({ ...prev, [name]: NameValue }));
  };

  const filterListData = [
    {
      type: "text",
      placeholder: "DC Agent Name",
      name: "agentName",
    },
    {
      type: "text",
      placeholder: "Agent ID",
      name: "agentId",
    },
    {
      type: "text",
      placeholder: "Contact Number",
      name: "mobileNo",
    },

  
    {
      type: "select",
      placeholder: "Region",
      name: "region_name",
      options: [...RegionState],
      api: global.getAllRegions,
      labelKey: "region_name",
      valueKey: "region_code",
      state: setRegionState,
    },
    !isBranchAdmin && {
      type: "select",
      placeholder: "Branch Name",
      name: "branch_name",
      options: [...branchState],
      api: global.getAllBranches,
      labelKey: "branch_name",
      valueKey: "branch_id",
      state: setBranchState,
    },
  ].filter(Boolean);


  const handleFilterReset = () => {
    setFilterVal({
      agentId:"",
      agentName:"",
      branch_name:"",
mobileNo:"",
region_name:""
    })
    setcurrentPage(1)
    setErrorMsg({})
    setIsFilterReset(true);
  };

  useEffect(() => {
    if (isFilterReset) {
      setFilter(false);
      setIsFilterReset(false);
    } else {
      fetchTableDatas();
    }
  }, [isFilterReset, currentPage, pageSize]);

useEffect(()=>{
  if (previousTab.current !== selectedTabs) {
    fetchTableDatas(1)
    previousTab.current = selectedTabs;
  }
},[selectedTabs])

  const fetchTableDatas =async (page) => {
    try {
      setIsLoader(true);
      let path;
      let reqstBody = {};
      let apiCalls = [];
      const agentIdValue = filterVal.agentId;
      const agentNameValue = filterVal.agentName;
      const agentMobileValue = filterVal.mobileNo;
      const branch_name = filterVal.branch_name;
      const Region_name = filterVal.region_name;
      const startingIndex = (currentPage - 1) * pageSize + 1;
      if(page){
        setcurrentPage(prev => page)
      }
         let params = agentNameValue ? `name=${agentNameValue}&` : "";
          params = params + (agentIdValue ? `agentId=${agentIdValue}&` : "");
          params =
            params + (agentMobileValue ? `mobileNo=${agentMobileValue}&` : "");
          params = params + (branch_name ? `branchId=${branch_name}&` : "");
          params = params + (Region_name ? `regionCode=${Region_name}&` : "");
      switch (selectedTabs) {
        case "DCAgents":
          path =
            global.getAgentList +
            `?${params}page=${currentPage}&limit=${pageSize}`;
          reqstBody = {};
          break;
        case "DCAgentsBlocked":
          path =
            global.getAgentList +
            `?${params}isBlocked=true&page=${currentPage}&limit=${pageSize}`;
          reqstBody = {};
          break;
        default:
          break;
      }
      // debugger
      apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));

      const responses = await Promise.all(apiCalls);

      responses.forEach(({ message, response_data }) => {
        if (message === "Success") {
          const { data } = response_data || {};
          setTableData(
            data.docs.map((itm, index) => ({
              ...itm,
              key: startingIndex + index,
              branchName: itm.branch.name,
              lastLogin: itm.lastLogin
                ? moment(itm.lastLogin).format("DD/MM/YYYY hh:mm A")
                : "",
              RegionName:itm.branchDetails[0].parent_branch.name,
            }))
          );
          setTotalCount(data.totalDocs);
          setpageSize(parseInt(data.limit));
        } else {
        }
      });
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  async function commonApiCall({ path, reqstBody, method = "post" }) {
    if(!path) return 
    try {
      const result = await ManageApis(method, path, reqstBody);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const handleBlock = async () => {
    try {
      const agentId = RecordValue.agentId;
      const req = { agentId, is_blocked: !RecordValue.isBlocked };
      const result = await ManageApis("put", global.BlockAgent, req);
      setvisible({ show: false });
      fetchTableDatas();
    } catch (error) {
      console.error(error);
    }
  };

  const filterSection=async(name,val)=>{
    try {
      let path;
      let apiCalls=[];
     let reqstBody;
     let filterData;
        if(name=== 'region_name'){
          filterData=filterListData.filter((items)=>items.name === 'branch_name');
          path=filterData[0].api+`?regionCode=${val}`
        }else if(name ==='branch_name'){
          filterData=filterListData.filter((items)=>items.name === 'region_name');
        }
        apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));
      const response =await Promise.all(apiCalls);
      response.forEach(({ message, response_data }) => {
        if (message === "Success") {
          
          const { data } = response_data || {};
          const mappedData =  data.map((item) => {
            return {
            label: item[filterData[0].labelKey],
            value: item[filterData[0].valueKey],
          }});
          filterData[0].state(mappedData)
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }

  const filterListOptions = async () => {
try {
      if(!filter){
        let apiCalls = [];
        const filteredDataList = filterListData.filter((items) => items.api);
        for (const filter of filterListData) {
          const apiUrl = filter.api;
          const path = apiUrl;
          let reqstBody = {};
          if (path)
            apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));
        }
        const responses = await Promise.all(apiCalls);
  
        responses.forEach(({ message, response_data }, index) => {
          const filter = filteredDataList[index];
          if (message === "Success") {
            const { data } = response_data || {};
            const mappedData =  data.map((item) => {
              return {
              label: item[filter.labelKey],
              value: item[filter.valueKey],
              all:item
            }});
  
            filter.state(mappedData);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  function onChangeTable(current, size) {
    setcurrentPage(current);
    setpageSize(parseInt(size));
  }

  return (
    <>
      <div className="d-flex justify-content-between ">
        <div className="d-flex">
          <div
            className={`bg_light7 position-relative headStyle1  w_fit_content min_w_160x px-4 pt-3 pb-2 ${
              selectedTabs === "DCAgents" ? "active-tab" : ""
            }`}
            onClick={() => handleTabChange("DCAgents")}
            style={{ cursor: "pointer" }}
          >
            <h2 className="mb-0 collection_details mt-0 cursor-pointer text-start text_master font_16 font_500">
              Active DC Agents
            </h2>
            <div
              className={`headStyle ${
                selectedTabs === "DCAgents" ? "headStyle-active" : ""
              }`}
            ></div>
          </div>
          <div
            className={`ms-3 bg_light7 position-relative headStyle1 shadow_sm_4 w_fit_content min_w_160x px-4 pt-3 pb-2 ${
              selectedTabs === "DCAgentsBlocked" ? "active-tab" : ""
            }`}
            onClick={() => handleTabChange("DCAgentsBlocked")}
            style={{ cursor: "pointer" }}
          >
            <h2 className="mb-0 mt-0 Transaction_details text-start text_master font_16 font_500">
              Blocked DC Agents
            </h2>
            <div
              className={`headStyle ${
                selectedTabs === "DCAgentsBlocked" ? "headStyle-active" : ""
              }`}
            ></div>
          </div>
        </div>

        <FilterComponent
          filterInputData={filterListData}
          handleReset={handleFilterReset}
          handleChange={handleOnChange}
          value={filterVal}
          errorMsg={errorMsg}
          populateBranchOptions={() => filterListOptions()}
          handleApply={()=>{fetchTableDatas(1);setFilter(true)}}
          resetEnabled={filter}
        />
      </div>

      {DCAgentTableVisible && (
        <>
          <div className="pb-3  custom_pagination customise_table3 customise_table customise_Expand_table expandable-table shadow_sm_4 br_15 arco_table1 custom_scroll1">
            <DataTable
              rowData={TableData}
              columns={ActiveAgents}
              expandedRowRender={(record) => expandedRowRender(record)}
              pageSize={pageSize}
              currentPage={currentPage}
              changeIcon={setisExpand}
              total={totalCount}
              onChangeTable={(current, Size) => onChangeTable(current, Size)}
              scroll={totalCount > 5 ? { y: 400 } : ""}
              setPageSize={setpageSize}
              // total={data.length}
            />

          </div>
          <ModalComponent
            visible={visible.show}
            title={"Block DC Agent"}
            size="md"
            handleClose={handleModalClose}
          >
            <div>
              <h2 className="text-black font_600 font_17 mb-3 text-center">
              Are you sure you want to block the DC Agent? <br />
              </h2>
              <div className="text-end">
                <button onClick={()=>handleModalClose()} className="btn font_15 font_500 px-3 text_master">
                  Cancel
                </button>
                <button
                  onClick={() => handleBlock(true)}
                  className="btn btn-master ms-2 font_15 font_500 px-5"
                >
                  Apply
                </button>
              </div>
            </div>
          </ModalComponent>
        </>
      )}
      {DCAgentBlockTable && (
        <>
         <div className=" pb-3  custom_pagination customise_table3 customise_table customise_Expand_table expandable-table shadow_sm_4 br_15  custom_scroll1">
            <DataTable
              rowData={TableData}
              columns={BlockedDCAgents}
              expandedRowRender={expandedRowRender}
              pageSize={pageSize}
              currentPage={currentPage}
              changeIcon={setisExpand}
              total={totalCount}
              onChangeTable={(current, Size) => onChangeTable(current, Size)}
              scroll={totalCount > 5 ? { y: 400 } : ""}
              setPageSize={setpageSize}
              // total={data.length}
            />
          
          </div>
          <ModalComponent
            visible={visible.show}
            title={"Block DC Agent"}
            size="md"
            handleClose={handleModalClose}
          >
            <div>
              <h2 className="text-black font_600 font_17 mb-3 text-center">
              Are you sure you want to Unblock the DC Agent? <br />
              </h2>
              <div className="text-end">
                <button onClick={()=>handleModalClose()} className="btn font_15 font_500 px-3 text_master">
                  Cancel
                </button>
                <button className="btn btn-master ms-2 font_15 font_500 px-5">
                  Apply
                </button>
              </div>
            </div>
          </ModalComponent>
          <ModalComponent
            visible={visible.show}
            title={"Unblock DC Agent"}
            size="md"
            handleClose={handleModalClose2}
          >
            <div>
              <h2 className="text-black font_600 font_17 mb-3 text-center">
              Are you sure you want to Unblock the DC Agent?<br />
              </h2>
              <div className="text-end">
                <button onClick={()=>handleModalClose()} className="btn font_15 font_500 px-3 text_master">
                  Cancel
                </button>
                <button
                  onClick={() => handleBlock(false)}
                  className="btn btn-master ms-2 font_15 font_500 px-5"
                >
                  Apply
                </button>
              </div>
            </div>
          </ModalComponent>
        </>
      )}
    </>
  );
}

export default Agents;
