import React from 'react'
import Logo from '../../assets/images/logo1.png';
import { Radio } from '@arco-design/web-react';
const RadioGroup = Radio.Group;

function Header() {
  const handleNavigateCustomerDashboard = () => window.location.href = `http://localhost:3000/dashboard`
  return (
    <div className='bg-white position-relative zi_10 shadow_sm3 px-3 py-1'>
      <div className='d-flex justify-content-between align-items-center p-3'>
       <div></div>
        <img src={Logo} height={37} />
      </div>
    </div>
  );
}

export default Header;
