// BarChart.js
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";
import { ManageApis } from "../../AppServices/ManageApi";
import moment from "moment";
import Loader from "../Loader/Loader";
import { globalContext } from "../../App";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const BarChart = ({ dateRange }) => {
  const [barData, setBarData] = useState(null);
  const { setIsLoader } = useContext(globalContext);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    // debugger
    if (dateRange && dateRange.length === 2) {
      fetchTableData();
    }
  }, [dateRange]);

  const fetchTableData = async () => {
    setIsLoader(true);
    try {
      let path =
        global.getDashMonthly +
        (dateRange && dateRange.length === 2
          ? `?startDate=${dateRange[0]}&endDate=${dateRange[1]}`
          : "");
      let reqstBody = {};
      const response = await commonApiCall({ path, reqstBody, method: "get" });
      if (response.response_data.data) {
        setBarData((prev) => response.response_data.data);
        // console.log("bar", response.response_data.data);
      } else {
        // Handle error case if necessary
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoader(false);
    }
  };

  async function commonApiCall({ path, reqstBody, method = "post" }) {
    try {
      if(!path) return 
      const result = await ManageApis(method, path, reqstBody);
      // console.log("RESULT..................", result);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // const extractDataForChart = (apiData) => {
  //   const labels = apiData.map(item => moment(item.date, 'DD-MM-YYYY').format('MM YYYY'));
  //   const paidByCashData = apiData.map(item => item.paidByCash);
  //   const paidByChequeData = apiData.map(item => item.paidByCheque);
  //   return { labels, paidByCashData, paidByChequeData };
  // };

const extractDataForChart = (apiData) => {
  let labels = [];
  let paidByCashData = [];
  let paidByChequeData = [];

  for (const date in apiData) {
    if (apiData.hasOwnProperty(date)) {
      const dataForDate = apiData[date];
      const totalPaidByCash = dataForDate.reduce(
        (acc, item) => acc + item.paidByCash,
        0
      );
      const totalPaidByCheque = dataForDate.reduce(
        (acc, item) => acc + item.paidByCheque,
        0
      );

      labels.push(date);
      paidByCashData.push(totalPaidByCash);
      paidByChequeData.push(totalPaidByCheque);
    }
  }

  return { labels, paidByCashData, paidByChequeData };
};

  const { labels, paidByCashData, paidByChequeData } = barData
    ? extractDataForChart(barData)
    : { labels: [], paidByCashData: [], paidByChequeData: [] };

  const data = {
    labels,
    datasets: [
      {
        label: "Cash",
        data: paidByCashData,
        backgroundColor: "#1D3B6D",
      },
      {
        label: "Cheque",
        data: paidByChequeData,
        backgroundColor: "rgba(141, 187, 48, 1)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
};

export default BarChart;
