import React, { useState } from 'react'
import InputField from '../InputField/InputField';
import { useEffect } from 'react';
import moment from 'moment';


function FilterComponent({ filterInputData = [], handleDownload, handleChange, handleReset,value,errorMsg,populateBranchOptions,handleApply,hasErrors,resetEnabled,onclear}) {
    const [moved, setMoved] = useState(false);
    const handleFilterClick = () => {
        setMoved(prev =>!moved);
        if(populateBranchOptions){
            populateBranchOptions();
        }
    };

const [clickedReset, setClickedReset] = useState(true)
const isFilterEmpty = Object.values(value).every(val => val === undefined || val === "");

const handleResetFilter=()=>{
    handleReset();
    setClickedReset(!clickedReset);
    setMoved(!moved)
}

const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

return (
        <div className='position-relative d-flex gap-2 '>
            <div
                className={`shadow_sm_6 w_32x h_32x d-flex align-items-center justify-content-center cursor-pointer bg-white br_6`}  onClick={handleFilterClick}>
                <i className="fas fa-filter text_master"></i>
            </div>
            <div
                className={`shadow_sm_6 w_32x h_32x d-flex align-items-center justify-content-center cursor-pointer bg-white br_6 ${!handleDownload && 'd-none'}`}>
                <i class="fas fa-download text_master"></i>
            </div>
            <div className={`your-custom-class1 shadow_sm_4 p-3 bg-white br_15 position-absolute zi_10 ${moved ? 'moved' : ''}`}>
                <div className='position-absolute end-0 me-2 cursor-pointer top-0 mt-2' onClick={handleFilterClick}>
                    <i class="fas fa-times"></i>
                </div>
                <h2 className='text-black font_15 mt-2 font_400'>Filter By</h2>
                <div>
                    <div className='row '>
                        {filterInputData.map((itm) => (
                            <div className='col-6'>
                                <div className='arco_select w-100'>
                                    {/* {console.log("values here ==>",itm.value)} */}
                                    <InputField
                                        onClear={onclear}
                                        required
                                        type={itm.type}
                                        placeholder={itm.placeholder}
                                        options={itm.options || []}
                                        onchange={(value,label,index)=>handleChange(value,label,index,itm.name)}
                                        name={itm.name}
                                        disabledDate={disabledDate}
                                        value={value[itm.name]|| ""}
                                        // value={filterValue.agentName || ""}
                                        errorMessage={errorMsg?.[itm?.name]}
                                        seterrorMessage={(name,value)=>console.log('NAME;',name,'MESSASGE',value)}
                                        reset={clickedReset}
                                    />
                                </div>
                                
                            </div>
                        ))}
                    </div>
                </div>
                <div className='text-end d-flex justify-content-end'>
                    <button className='btn font_14 font_500 w_fit_content btn-master-line text_master' disabled={!resetEnabled} onClick={()=>handleResetFilter()}>Reset Filter</button>
                    <button className='btn btn-master w_fit_content px-4 font_14 font_500 ms-2'  disabled={isFilterEmpty} onClick={() => {
    const hasErrors = Object.values(errorMsg).some((msg) => msg !== '');
    if (!hasErrors) {
        handleApply(); 
        setMoved(!moved); 
    }
}}>Apply Filter</button>
                </div>
            </div>
        </div>
    )
}

export default FilterComponent