import axios from "axios";
import { getLocalStorage, setLocalStorage } from "../Common/Utils/Utils";

// import Cookies from "js-cookie";

// axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.xsrfCookieName = "csrftoken";
const axiosInstance = axios.create({
  baseURL:"https://mobileapi.ksfe.com/admin/",
  // baseURL: "http://192.168.0.152:3002/admin",
  headers: {
    Authorization: localStorage.getItem("token"),
  },
});
// console.log('baseURL >>>>>>>>>>>>',global.baseURL)
// console.log("    dsfdsf ,  \n\n\n\n\n",window.CONFIGS.baseUrl,getLocalStorage("access_token"))
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    const originalRequest = error.config;
    // console.log(error)
    //debugger
    if (error.response.status === 401) {
      const refreshToken = getLocalStorage("refresh_token");

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post("", { refresh: refreshToken })
            .then((response) => {
              // console.log(response)

              setLocalStorage({ access_token: response.data.access });
              axiosInstance.defaults.headers["Authorization"] =
                "Bearer " + response.data.access;
              originalRequest.headers["Authorization"] =
                "Bearer " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              // console.log(err);
            });
        } else {
          // console.log("Refresh token is expired", tokenParts.exp, now);
          // alert("Session Expired")
          return { message: "Token is expired", status: 401 };
          // window.location.href = "/customer";
        }
      } else {
        return 0;
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
