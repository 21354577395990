import React from 'react';

const LimitCard = ({ title, limit, imgSrc, onEdit,disabled }) => {

  // const data=[
  //   {title},
  //   {}
  // ];

  return (
    <div className="br_16 bg_gray11 shadow_sm_7">
      <div className="pe-3 pt-3 text-end">
        <button
          style={{ border: "1px solid #1D3B6D4D" }}
          className="btn px_btn py-1 rounded text-black font_600 font_12 text-nowrap"
          onClick={onEdit}
          disabled={disabled}
        >
          Edit
        </button>
      </div>
      <div className="d-flex pb-5 pt-3 px-3 align-items-center">
        <div
          style={{ width: "105px", height: "100px" }}
          className="position-relative d-flex align-items-center justify-content-center rounded-pill light5"
        >
          <img
            className="position-absolute"
            style={{ width: "65px", height: "65px" }}
            src={imgSrc}
            alt={title} 
          />
        </div>
        <div className="ms-3">
          <h2 className="mb-2 text-black font_15 font_600">
            {title}
          </h2>
          <h1 className="mb-0 mt-0 text_master font_32 font_600">
            &#x20b9; {limit}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default LimitCard;
