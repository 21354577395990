import React, { createContext, useCallback, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./assets/font-awesome/css/all.min.css";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/config.css";
import "./assets/css/common.css";
import "./assets/css/style.css";
import "./App.css";
import "@arco-design/web-react/dist/css/arco.css";
import enUS from "@arco-design/web-react/es/locale/en-US";
import LayoutMain from "./Components/Layoutmain/LayoutMain";
import { ConfigProvider } from "@arco-design/web-react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./Common/config/global";
import {
  getData,
  getLocalStorage,
  setLocalStorage,
  storeLocalData,
} from "./Common/Utils/Utils";
import { ManageApis } from "./AppServices/ManageApi";

export const globalContext = createContext();

function App() {
  const [isLoader, setIsLoader] = useState(false);
  const [user, setUser] = useState("");
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [isBranchAdmin, setIsBranchAdmin] = useState(false);

  // const location = useLocation();
  const path = window.location.pathname;

  useEffect(() => {
    if (path == "/") {
      window.location.replace("/agent-admin");
    }
  }, [path]);

  useEffect(() => {
    getRoles();
    getPermissions();
    handleTokens();
  }, []);

  useEffect(() => {
    const localUser = localStorage.getItem("user");
    if (localUser) {
      setUser(localUser);
      matchUserRole(localUser);
      setIsBranchAdmin(localUser === 'branchAdmin');
    }
  }, [roles, permissions]);

  const handleTokens = useCallback(() => {
    try {
      const storageListener = window.addEventListener("storage", (event) => {
        if (
          !event?.localStorage?.token?.length ||
          !event?.localStorage?.user?.length
        )
          window.location.href = global.vueBaseUrl;
      });

      const searchParams = new URLSearchParams(window.location.search);
      const currentUser = searchParams.get("user");
      const token = searchParams.get("localStorageVariable");

      const localUser = localStorage.getItem("user");
      if (searchParams.get("user")) {
        // debugger;

        storeLocalData("token", token);
        storeLocalData("user", currentUser);
        // setUser(currentUser);
        window.location.href = "/agent-admin";
      }
      if (!localUser || localUser != "null" || localUser != false) {
        setUser(localUser);
        // debugger;
        if ((!currentUser || !token) && !localUser)
          window.location.replace(global.vueBaseUrl);

        return;
      }

      //setting current user
      // console.log("current user here --->", searchParams.get("user"));

      // setUser(currentUser);

      // debugger;

      //setting token

      const val = getLocalStorage("user");

      return () => {
        window.removeEventListener("storage", storageListener);
      };
      // console.log("token here -->", getLocalStorage("token"));
    } catch (error) {
      console.error("Error setting user data:", error);
    }
  }, [user]);

  const matchUserRole = (localUser) => {
    const matchedRole = roles.find((role) => role.label === localUser);
    if (matchedRole) {
      const userRolePermissions = matchedRole.permissions;
      const matchedPermissions = permissions
        .filter((permission) => userRolePermissions.includes(permission.value))
        .map(({ value, label }) => ({ value, label }));
      setUserPermissions(matchedPermissions);
    }
  };

  const getRoles = async () => {
    try {
      const path = global.roles;
      let reqstBody = {};
      const response = await commonApiCall({ path, reqstBody, method: "get" });
      if (response.message === "Success") {
        const { data } = response.response_data || {};
        const formattedRoles = data.map((role) => ({
          value: role.id,
          label: role.name,
          permissions: role.permissions,
        }));
        setRoles(formattedRoles);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPermissions = async () => {
    try {
      const path = global.permissions;
      let reqstBody = {};
      const response = await commonApiCall({ path, reqstBody, method: "get" });
      if (response.message === "Success") {
        const { data } = response.response_data || {};
        const formattedPermissions = data.map((permission) => ({
          value: permission.id,
          label: permission.name,
        }));
        setPermissions(formattedPermissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function commonApiCall({ path, reqstBody, method = "post" }) {
    if (!path) return;
    try {
      const result = await ManageApis(method, path, reqstBody);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  useEffect(() => {
    try {
      window.addEventListener("error", (e) => {
        if (
          e.message ===
            "ResizeObserver loop completed with undelivered notifications." ||
          e.message === "ResizeObserver loop limit exceeded"
        ) {
          e.stopImmediatePropagation();
          e.preventDefault();

          const overlay = document.querySelector(
            "#webpack-dev-server-client-overlay"
          );

          // Check if the overlay element exists before trying to modify its style
          if (overlay) {
            overlay.style.display = "none";
          } else {
            // console.log('Overlay element not found.');
          }
        }
      });
    } catch (error) {
      // Handle other errors if needed
    }
  }, []);

  return (
    <>
      <ConfigProvider locale={enUS}>
        <Router basename="/agent-admin">
          <globalContext.Provider
            value={{
              isLoader,
              setIsLoader,
              user,
              setUser,
              userPermissions,
              setUserPermissions,
              isBranchAdmin,
              setIsBranchAdmin
            }}
          >
            <LayoutMain />
          </globalContext.Provider>
        </Router>
      </ConfigProvider>
    </>
  );
}

export default App;
