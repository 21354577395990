import React, { useCallback, useContext, useEffect, useState } from 'react'
import img1  from '../../assets/images/money1.svg'
import ModalComponent from '../ModalComponent/ModalComponent'
import InputField from '../InputField/InputField';
import { ManageApis } from '../../AppServices/ManageApi';
import { globalContext } from '../../App';

function DailyTransactionSummary() {

    
    const [cashLimit,setCashLimit]=useState();
    const [chequeLimit,setChequeLimit]=useState();

    const initialVisibleValue = { show: false, type: "" }
    const [visible, setvisible] = useState(initialVisibleValue);
    const {setIsLoader}=useContext(globalContext)
    const handleModalClose = () => {
        setvisible(initialVisibleValue);
        // setRowData({})
    }

    useEffect(()=>{
        fetchagentId();
        },[])
        
          const fetchagentId =async () => {
            setIsLoader(true)
            try {
              let apiCalls = [];
                const path = global.FetchLimit;
                let reqstBody = {};
                apiCalls.push(commonApiCall({ path, reqstBody, method: "get" }));
                      const responses = await Promise.all(apiCalls);
              responses.forEach(({ message, response_data }, index) => {
                if (message === "Success") {
                  const { data } = response_data || {};
                  // console.log("Data.....", data.cash);
            setCashLimit(data.cash);
            setChequeLimit(data.cheque)
                } else {}
              });
            } catch (error) {
              console.error(error);
            }finally{
              setIsLoader(false)
            }
          }
          
          async function commonApiCall({ path, reqstBody ,method="post"}) {
            if(!path) return 
            try {
              const result = await ManageApis(method, path, reqstBody);
              // console.log(result);
              return result;
            } catch (error) {
              console.error(error);
              throw error;
            }
          }


  return (
    <>
    <div className=''>
        <h2 className='text-black fw_700 font_20'>Cash/Cheque limit</h2>
        <div className='row mt-3'>
            <div className='col-12 mb-3'>
                <div className='br_16 bg-white shadow_sm_4'>
                    {/* <div className='pe-3 pt-3 text-end '>
                        <button className='btn px_btn btn-master-line py-1 rounded text-black font_600 font_12 text-nowrap' onClick={() => setvisible({show :true})}>Edit</button>
                    </div> */}
                    <div className='d-flex  py-4 px-3  align-items-center'>
                    <div style={{width:"105px",height:"100px"}} className='position-relative d-flex align-items-center justify-content-center rounded-pill light5'>
                    <div className='position-absolute w_30x h_30x d-flex align-items-center justify-content-center bottom-0 end-0 bg_master rounded-pill'>
                        <i class="fas fa-arrow-up text-white  rounded-pill"></i>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 59 56" fill="none">
                        <g clip-path="url(#clip0_757_1086)">
                        <path d="M58.0892 4.19775H1.19922V55.4177H58.0892V4.19775Z" fill="white" stroke="black" stroke-width="0.945017"/>
                        <path d="M57.6169 4.67041H1.67188V12.9866H57.6169V4.67041Z" fill="#1D3B6D"/>
                        <path d="M9.60941 46.062H3.4668V52.2046H9.60941V46.062Z" fill="#D9D9D9"/>
                        <path d="M17.2637 46.062H11.1211V52.2046H17.2637V46.062Z" fill="#D9D9D9"/>
                        <path d="M24.918 46.062H18.7754V52.2046H24.918V46.062Z" fill="#D9D9D9"/>
                        <path d="M17.2637 15.4434H11.1211V21.586H17.2637V15.4434Z" fill="#D9D9D9"/>
                        <path d="M24.918 15.4434H18.7754V21.586H24.918V15.4434Z" fill="#D9D9D9"/>
                        <path d="M32.5723 15.4434H26.4297V21.586H32.5723V15.4434Z" fill="#D9D9D9"/>
                        <path d="M40.2285 15.4434H34.0859V21.586H40.2285V15.4434Z" fill="#D9D9D9"/>
                        <path d="M47.8828 15.4434H41.7402V21.586H47.8828V15.4434Z" fill="#D9D9D9"/>
                        <path d="M55.5371 15.4434H49.3945V21.586H55.5371V15.4434Z" fill="#D9D9D9"/>
                        <path d="M9.60941 23.0981H3.4668V29.2408H9.60941V23.0981Z" fill="#D9D9D9"/>
                        <path d="M17.2637 23.0981H11.1211V29.2408H17.2637V23.0981Z" fill="#D9D9D9"/>
                        <path d="M24.918 23.0981H18.7754V29.2408H24.918V23.0981Z" fill="#D9D9D9"/>
                        <path d="M32.5723 23.0981H26.4297V29.2408H32.5723V23.0981Z" fill="#D9D9D9"/>
                        <path d="M40.2285 23.0981H34.0859V29.2408H40.2285V23.0981Z" fill="#D9D9D9"/>
                        <path d="M47.8828 23.0981H41.7402V29.2408H47.8828V23.0981Z" fill="#D9D9D9"/>
                        <path d="M55.5371 23.0981H49.3945V29.2408H55.5371V23.0981Z" fill="#D9D9D9"/>
                        <path d="M9.60941 30.7524H3.4668V36.8951H9.60941V30.7524Z" fill="#D9D9D9"/>
                        <path d="M17.2637 30.7524H11.1211V36.8951H17.2637V30.7524Z" fill="#D9D9D9"/>
                        <path d="M24.918 30.7524H18.7754V36.8951H24.918V30.7524Z" fill="#D9D9D9"/>
                        <path d="M32.5723 30.7524H26.4297V36.8951H32.5723V30.7524Z" fill="#1D3B6D"/>
                        <path d="M40.2285 30.7524H34.0859V36.8951H40.2285V30.7524Z" fill="#D9D9D9"/>
                        <path d="M47.8828 30.7524H41.7402V36.8951H47.8828V30.7524Z" fill="#D9D9D9"/>
                        <path d="M55.5371 30.7524H49.3945V36.8951H55.5371V30.7524Z" fill="#D9D9D9"/>
                        <path d="M9.60941 38.4072H3.4668V44.5498H9.60941V38.4072Z" fill="#D9D9D9"/>
                        <path d="M17.2637 38.4072H11.1211V44.5498H17.2637V38.4072Z" fill="#D9D9D9"/>
                        <path d="M24.918 38.4072H18.7754V44.5498H24.918V38.4072Z" fill="#D9D9D9"/>
                        <path d="M32.5723 38.4072H26.4297V44.5498H32.5723V38.4072Z" fill="#D9D9D9"/>
                        <path d="M40.2285 38.4072H34.0859V44.5498H40.2285V38.4072Z" fill="#D9D9D9"/>
                        <path d="M47.8828 38.4072H41.7402V44.5498H47.8828V38.4072Z" fill="#D9D9D9"/>
                        <path d="M55.5371 38.4072H49.3945V44.5498H55.5371V38.4072Z" fill="#D9D9D9"/>
                        <path d="M7.81454 1.12646H7.81453C7.26652 1.12646 6.82227 1.57072 6.82227 2.11873V6.46581C6.82227 7.01382 7.26652 7.45808 7.81453 7.45808H7.81454C8.36255 7.45808 8.80681 7.01382 8.80681 6.46581V2.11873C8.80681 1.57072 8.36255 1.12646 7.81454 1.12646Z" fill="#D9D9D9" stroke="black" stroke-width="0.472509"/>
                        <path d="M51.2852 1.12646H51.2852C50.7372 1.12646 50.293 1.57072 50.293 2.11873V6.46581C50.293 7.01382 50.7372 7.45808 51.2852 7.45808H51.2852C51.8333 7.45808 52.2775 7.01382 52.2775 6.46581V2.11873C52.2775 1.57072 51.8333 1.12646 51.2852 1.12646Z" fill="#D9D9D9" stroke="black" stroke-width="0.472509"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_757_1086">
                        <rect width="59" height="56" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>   
                    </div>
                    <div className='ms-3'>
                        <h2 className=' mb-2 text-black font_15 font_600'>Daily cash limit</h2>
                        <h1 className='mb-0 mt-0 text_master font_32 font_600'>&#x20b9; {cashLimit}</h1>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <div className='br_16 bg-white shadow_sm_4'>
                    {/* <div className='pe-3 pt-3 text-end '>
                        <button className='btn px_btn btn-master-line py-1 rounded text-black font_600 font_12 text-nowrap' onClick={() => setvisible({show :true})}>Edit</button>
                    </div> */}
                    <div className='d-flex  px-3 py-4 align-items-center'>
                        <div style={{width:"105px",height:"100px"}} className='position-relative rounded-pill light5'>
                        <img className='position-absolute' style={{width:"110px",height:"110px"}} src={img1}/>
                        </div>
                        <div className='ms-3'>
                            <h2 className=' mb-2 text-black font_15 font_600'>Daily cheque limit</h2>
                            <h1 className='mb-0 mt-0 text_master font_32 font_600'>&#x20b9; {chequeLimit}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalComponent visible={visible.show} title={"Set Daily cash limit"} size="md" handleClose={handleModalClose}>
        <div>
            <h2 className='text-black font_600 font_17 mb-3'>Specify the daily cash limit for each customer</h2>
            <InputField 
                required 
                type={'text'} 
                placeholder={"Enter Daily Limit"}
            />
            <div className='text-end'>
                <button className='btn font_15 font_500 px-3 text_master'>Cancel</button>
                <button className='btn btn-master ms-2 font_15 font_500 px-5'>Apply</button>
            </div>
        </div>
    </ModalComponent>
    </>
  )
}

export default DailyTransactionSummary