global.baseUrl = "https://mobileapi.ksfe.com/admin/";
// global.baseUrl = "https://stagemobileapi.ksfeonline.com/admin";
// global.baseUrl = "https://localhost:3002/admin";
global.disableLogs = "true";
global.version = "1.0.4";
global.getCollectionDetails = "/collections/details";
global.getSchemeDetails = "/collections/details-by-chit";
global.getBlockedAgentList = "/blocked-agent-list";
global.getAgentList = "/agents-list";
global.getSchemeDetailsList = "/scheme-list";
global.getReportList = "/login_report/list";
global.getDashSummary = "/dashboard/summary";
global.BlockAgent = "/block-agent";
global.getAllBranches = "/getAllBranches";
global.getAllRegions = "/getAllRegions";
global.getDashMonthly = "/dashboard/transaction-summary";
global.numUpdate = "/settings/update-agent-number";
global.FetchLimit = "/settings/collection-limit";
global.updateLimit = "/settings/update-collection-limit";
global.Reports = "/report/agents";
global.ReportsLogs = "/report/agent-logs";
global.downloads = "/report/download-agent-logs";
global.permissions = "/permissions";
global.roles = "/roles";
global.collectionLimit = "/settings/update-installment-limit";

global.vueBaseUrl = "https://mobileadmin.ksfe.com/";
// global.vueBaseUrl = "http://localhost:3000/";
global.vueDashboard = global.vueBaseUrl + "dashboard";
global.vueLogin = global.vueBaseUrl + "login";
