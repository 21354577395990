import React from 'react'
import { Table } from '@arco-design/web-react'
import PaginationComponent from '@arco-design/web-react/es/Pagination/pagination'

function DataTable({columns,rowData,pageSize,currentPage,expandedRowRender,onChangeTable,total,changeIcon,scroll,setPageSize}) {
    // console.log("------------------",columns)
    
    return (
        <div>


            <Table
            
                columns={columns}
                data={rowData}
                pagination={false}
                sticky
                // scroll={{ y: 400 }}
                scroll={scroll}
                expandedRowRender={(v)=>expandedRowRender(v)}
                onExpand={(detail, expanded) => {
                    if(expanded){
                    changeIcon(detail._id)
                    }
                else {changeIcon(prev => '')
                }
                   
                    
                }}

                onExpandedRowsChange={(expandedRows) => {
                    // console.log('.......expandRows',expandedRows);
                }}
                
                expandProps={ {
                
                  icon: ({ expanded, record, ...restProps }) =>
                  
                 ( <></>),
                width:0,
                  expandRowByClick: expandedRowRender?true:false,
                
                  rowExpandable: (record) => record.key !== '5',
                }}
            />
            <div className={`pagination-wrapper ${!pageSize&&'d-none'}`}>
                {/* Style the last pagination differently */}
                <div className='pagination-last'>
                    <PaginationComponent
                        simple
                        sizeCanChange
                        showTotal
                        pageSize={pageSize || 0}
                        current={currentPage || 0}
                        onChange={(current,size,)=>{setPageSize(size);onChangeTable(current,size);}}
                        total={total||0}
                      
                    />
                </div>
            </div>

        </div>
    )
}

export default DataTable