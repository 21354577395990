import React from 'react'
import img1 from '../../assets/images/Error-amico.jpg'
import { useNavigate } from 'react-router-dom';

function ErrorPage() {

  const navigate = useNavigate();
  return (
    <>
<div className="bg_head">
        <div className="d-flex h-100 p-4">
          <div className="br_25 m-auto overflow-hidden w-100 flex-column flex-sm-row d-flex loginbx">
           <div className='w-100 h-100'>
            <div className='d-flex h-100 py-4 px-3 px-md-0 py-md-0 flex-column justify-content-center align-items-center'>
                <img src={img1} className='ErrorImg br_15' alt="" />
                <h2 className='fw_600 sectionHeading text-center mt-3 mb-1'>Page not found</h2>
                <p className='mt-1 font_15 font_500 text-center'>Oops! The page you are looking for does not exist. It might have been moved or deleted</p>
                <div className='text-center mt-3'>
                <button onClick={() => { navigate("/"); localStorage.clear()}} className='font_14 btn btn-master'>Back to Home</button>
                </div>
              </div>
            </div>
              <div>
           </div> 
          </div>
        </div>
      </div>

    </>
  )
}

export default ErrorPage