import axiosInstance from "./axios";

export const GET_API = async (path, headers = "", params, resp) => {
  var config = {
    method: "get",
    url: path,
    responseType: resp,
  };
  // console.log("PATH====>>", path, global.baseUrl);
  let response = await axiosInstance(config)
    .then((response) => {
      console.log("get  API response", response);
      return response;
    })
    .catch((error) => {
      // console.log("POST API error", error);
      //debugger
      if (error.response) {
        return error.response;
      } else {
        return { status: 503, message: "NetWork Error !" };
      }
    });

  return ResponseValidation(response);
};

//post method calling function

export const POST_API = async (data, path) => {
  var config = {
    method: "post",
    url: path,
    // headers: headers,
    data: data,
  };
  // debugger
  // console.log(global.baseUrl);
  // console.log("POST API config", config);

  let response = await axiosInstance
    .post(path, data)
    .then((response) => {
      // console.log("POST API response", response);
      return response;
    })
    .catch((error) => {
      // console.log("POST API error", error);
      //debugger
      if (error.response) {
        return error.response;
      } else {
        return { status: 503, message: "NetWork Error !" };
      }
    });

  return ResponseValidation(response);
};

export const PUT_API = async (path, data) => {
  let response = await axiosInstance
    .put(path, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return { status: 503, message: "NetWork Error !" };
      }
    });
  return ResponseValidation(response);
};

//api response validate function

export const ResponseValidation = (response) => {
  // console.log("------->",response)
  const searchParams = new URLSearchParams(window.location.search);
  const isInitial = searchParams.get("user") ? true : false;
  let status_code = null;
  let response_data = null;
  let message = null;
  if (response) {
    if (response.status) {
      // console.log("res status", response.status);
      if (response.status === 201) {
        message = "Success";
        status_code = response.status;
        response_data = response.data;
      } else if (response.status === 200) {
        message = "Success";
        status_code = response.status;
        response_data = response.data;
      } else if (response.status === 401) {
        // console.log({ response });
        if (!isInitial) {
          window.location.href = global.vueLogin;
        }
        message = "UnAutherized Invalid Key ! ";
        status_code = response.status;
        response_data = 0;
      } else if (response.status === 400) {
        // console.log({ response });
        message = "Bad Request";
        status_code = response.status;
        response_data = response.data;
      } else if (response.status === 203) {
        // console.log({ response });
        message = response.data.Msg;
        status_code = response.status;
        response_data = response.data;
      } else if (response.status === 404) {
        message = "Page Not found";
        status_code = response.status;
        response_data = response.data;
        // console.log(JSON.stringify(response_data));
      } else if (response.status === 406) {
        message = "No visible text in page";
        status_code = response.status;
        response_data = 0;
        // console.log(JSON.stringify(response_data));
      } else if (response.status === 403) {
        if (!isInitial) {
          window.location.href = global.vueLogin;
        }
        message = "Not Authenticated'";
        status_code = response.status;
        response_data = 0;
        // console.log(JSON.stringify(response_data));
      } else if (response.status === 500) {
        message =
          "Sorry for the inconvenience. Something went wrong. Please try again later.";
        status_code = response.status;
        response_data =
          response.data ||
          "Sorry for the inconvenience. Something went wrong. Please try again later.";
      } else if (response.status === 504) {
        message = "504 Gateway Time-out";
        status_code = response.status;
        response_data = 0;
      } else if (response.status === 502) {
        message = "Bad Gateway ";
        status_code = response.status;
        response_data = 0;
      } else if (response.status === 503) {
        message = "Seems like server is not running";
        status_code = response.status;
        response_data = 0;
      } else {
        message = "Unhandled Condition met";
        response_data = 0;
      }

      return { response_data, message, status_code };
    } else {
      message = "unexpected response got";
      // console.log("Response validation - No response status", response);

      return status_code;
    }
  } else {
    message = "Session Expired";
    response_data = 0;
    status_code = 0;
    if (!isInitial) {
      window.location.href = global.vueLogin;
    }
    return { response_data, message, status_code };
  }
};
