import React, { useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { RouteList, getroutes } from "../../Common/Constant";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { globalContext } from "../../App";
import { Radio } from "@arco-design/web-react";
const RadioGroup = Radio.Group;

// import { FaChartPie, FaChartLine } from 'react-icons/fa'; // Import the icons you want to use
function SidebarMenu() {

  const handleNavigateCustomerDashboard = () =>
    (window.location.href = global.vueDashboard);

  const { pathname } = useLocation();
  const { user } = useContext(globalContext);
  const userRoutes = getroutes(user);
  return (
    <>
    <div className="menu-demo position-relative bg_master p-2 h-100 pt-4">
      <div className="customRadio d-flex justify-content-center mb-3">
        <RadioGroup
          type="button"
          name="lang"
          defaultValue="Agent"
          size="large"
          // style={{ marginBottom: 20 }}
        >
          <Radio value="Agent">Agent</Radio>
          <Radio value="Customer" onClick={handleNavigateCustomerDashboard}>
            Customer
          </Radio>
        </RadioGroup>
      </div>
      <Sidebar>
        <Menu
          iconShape="square"
          menuItemStyles={{
            button: {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              [`&.active`]: {
                backgroundColor: "#13395e",
                color: "#b6c8d9",
              },
            },
          }}
        >
          {userRoutes.map(
            (itm) =>
              itm.path && (
                <MenuItem
                  active={pathname == itm.path}
                  icon={itm.icon}
                  component={<Link to={itm.path} />}
                  // className='active-tab'
                >
                  {itm.name}
                </MenuItem>
              )
          )}
        </Menu>
      </Sidebar>
    </div>
    </>
  );
}

export default SidebarMenu;
