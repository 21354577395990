
import { GET_API, POST_API, PUT_API } from "./APIS";

export const ManageApis = async (method, path, params, headers,resp_type) => {
//  debugger
  if (method === "post") {
    // console.log("apicall params", "method==>",method,"Path==>",path,"Params==>",params,"Header==>",headers)
 

      let results = POST_API(params,  path);
      return results.then((results)=>{
        // console.log(results)
          if(results.status_code){
            // console.log("manageapiresponse",results)
              return results;
  
          }
          else{
            return results.message
          }
         
      });
    
  }else if (method === "get") {
    // console.log("apicall params",method,path,params,headers)
      let results = GET_API(path,headers,params,resp_type);
      return results.then((results)=>{
        // console.log("kiooiiiiii",results)
          if(results.status_code){
            // console.log("manageapiresponse",results)
              return results;
  
          }
          else{
            return results.message
          }
         
      });
    
  }else if(method ==="put"){
    let results=PUT_API(path,params,headers);
    return results.then((results)=>{
      if(results.status_code){
      // console.log("manageapiresponse",results)
return results;
      }else{
        return results.message
      }
    })
  }
};